<template>
  <section>
    <div class="columns is-centered">
      <div class="column is-9">
        <h1 class="subtitle">Informes Individuales</h1>
        <p class="has-text-grey">
          Esta opción permite buscar muestras ya procesadas y descargar los
          informes asociados a éstas.
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half is-offset-one-quarter">
        <form @submit.prevent>
          <b-field>
            <b-input
              v-model="searchText"
              placeholder="N° de Muestra, RUN, Apellido..."
              expanded
            >
            </b-input>
            <p class="control">
              <b-button
                class="button is-primary"
                :loading="searching"
                :disabled="generating"
                native-type="submit"
                @click="searchSamples"
              >
                Buscar
              </b-button>
            </p>
          </b-field>
        </form>
      </div>
    </div>
    <div v-if="samplesData && samplesData.length > 0">
      <section class="has-text-centered">
        <b-checkbox
          v-if="$store.state.user.role !== 'report-viewer'"
          v-model="protect"
        >
          Proteger informes con contraseña
        </b-checkbox>
        <b-checkbox
          v-if="$store.state.user.role !== 'report-viewer'"
          v-model="english"
        >
          Generar reporte en inglés
        </b-checkbox>
      </section>
      <b-table :loading="searching" :data="samplesData">
        <b-table-column
          v-slot="props"
          field="sampleId"
          label="Muestra"
          cell-class="valign-mid"
          centered
          numeric
        >
          {{ props.row.sampleId }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="externalId"
          label="N° Pet. (Ex)"
          cell-class="valign-mid"
          centered
          numeric
        >
          <template v-if="props.row.externalId">
            {{ props.row.institution }}{{ props.row.externalId }}
          </template>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="idString"
          label="RUN"
          cell-class="valign-mid"
          centered
        >
          {{ props.row.idString }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Nombre"
          cell-class="valign-mid"
          centered
        >
          {{ props.row.names }} {{ props.row.firstLastname }}
          {{ props.row.secondLastname }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="conclusion"
          label="Conclusión / Estado"
          cell-class="valign-mid"
          centered
        >
          {{ getStatusDisplay(props.row) }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="validationDatetime"
          label="Validación"
          cell-class="valign-mid"
          centered
        >
          {{ formatDateTime(props.row.validationDatetime) }}
        </b-table-column>
        <b-table-column v-slot="props" cell-class="valign-mid" centered>
          <b-tooltip
            v-if="props.row.conclusion"
            type="is-light"
            label="Descargar Informe"
          >
            <b-button
              :loading="
                currentReport &&
                props.row.sampleId === currentReport &&
                generating
              "
              :disabled="currentReport && props.row.sampleId !== currentReport"
              type="is-primary"
              size="is-small"
              @click="generateReport(props.row.sampleId)"
            >
              <b-icon icon="file-document" custom-size="mdi-18px" />
            </b-button>
          </b-tooltip>
        </b-table-column>
      </b-table>
    </div>
    <div
      v-else-if="samplesData && samplesData.length === 0"
      class="has-text-centered"
    >
      <span class="has-text-danger">
        No se encontró ningún resultado con el término buscado.
      </span>
    </div>
  </section>
</template>

<script>
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import { formatDateTime } from "@/utils";

  export default {
    name: "IndividualReport",
    data() {
      return {
        searchText: "",
        protect: false,
        english: false,
        searching: false,
        generating: false,
        samplesData: undefined,
        currentReport: undefined,
      };
    },
    methods: {
      formatDateTime(datetime) {
        return formatDateTime(datetime, true);
      },
      searchSamples() {
        this.searching = true;
        this.axios
          .post(routes.searchSamples, { text: this.searchText })
          .then((response) => {
            this.samplesData = response.data.results;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.searching = false;
          });
      },
      generateReport(sampleId) {
        this.generating = true;
        this.currentReport = sampleId;
        this.axios
          .post(
            routes.getIndividualReport,
            {
              sampleId: sampleId,
              protect: this.protect,
              english: this.english,
            },
            { responseType: "blob" }
          )
          .then((response) => {
            const blob = new Blob([response.data]);
            saveAs(
              blob,
              `CADIUMAG_${sampleId}_${this.$date().format("YYYYMMDDHHmm")}.pdf`
            );
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.generating = false;
            this.currentReport = undefined;
          });
      },
      getStatusDisplay(sample) {
        if (sample.conclusion) {
          return sample.conclusion;
        } else {
          return sample.status.toUpperCase();
        }
      },
    },
  };
</script>

<style scoped></style>
