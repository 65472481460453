<template>
  <div class="container mt-5">
    <h1 class="title">Celdas de secuenciación genómica</h1>
    <div id="filterBar" class="container mb-5">
      <seq-cells-filter-bar>
        <template #default="{ filters }">
          <b-button type="is-primary" @click="loadData(filters)">
            Filtrar Datos
          </b-button>
        </template>
        <template #reset="{ cleanInput }">
          <b-button class="ml-2" type="is-danger" @click="cleanInput">
            Limpiar filtros
          </b-button>
        </template>
      </seq-cells-filter-bar>
    </div>
    <div class="columns is-centered">
      <b-button
        v-if="recordNumber > 0"
        type="is-primary"
        icon-left="file-excel"
        :loading="sheetGenerating"
        @click="downloadData()"
      >
        Descargar planilla de celdas
      </b-button>
    </div>
    <div class="table">
      <b-table
        :data="seqCells"
        :loading="loading"
        checkbox-position="left"
        sortable
        paginated
        :per-page="perPage"
        :current-page.sync="currentPage"
      >
        <b-table-column
          v-slot="{ row }"
          label="Nº registro"
          field="id"
          cell-class="valign-mid"
          centered
          sortable
          numeric
        >
          {{ row.id }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Número experimento"
          field="idString"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.experimentId }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Operador"
          field="operator"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.operator }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Equipo"
          field="seqEquipment"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.seqEquipment }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Kit ligación"
          field="ligationKit"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.ligationKit }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Fecha"
          field="startDatetime"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ formatDatetime(row.startDatetime) }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Muestras"
          field="samples"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.samples }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label=""
          cell-class="valign-mid"
          centered
        >
          <b-tooltip label="Detalles" type="is-light">
            <router-link
              :to="{ name: 'SeqCellDetails', params: { id: row.id } }"
            >
              <b-icon icon="information" type="is-primary" />
            </router-link>
          </b-tooltip>
        </b-table-column>
        <template #empty><div class="table-empty" /></template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import SeqCellsFilterBar from "@/components/sequencing/SeqCellsFilterBar.vue";

  export default {
    name: "SeqCellList",
    components: {
      SeqCellsFilterBar,
    },
    data() {
      return {
        loading: true,
        loadingOptions: false,
        sheetGenerating: false,
        currentPage: 1,
        perPage: 20,
        filterOptions: {},
        seqCells: [],
      };
    },
    computed: {
      recordNumber() {
        return this.seqCells.length;
      },
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData(filters = null) {
        this.loading = true;
        this.axios
          .post(routes.listSequencingCells, { filters: filters })
          .then((response) => {
            if (response.data.status === "OK") {
              this.seqCells = response.data.seqCells;
              this.inExtractionSamples = response.data.inExtractionSamples;
            } else {
              this.errorAlert(response.data.message);
            }
          })
          .catch(() => {
            this.errorAlert();
          })
          .finally(() => {
            this.loading = false;
          });
      },
      formatDatetime(datetime) {
        return this.$date(datetime).format("DD/MM/YYYY");
      },
      errorAlert(message = null) {
        if (!message) {
          message = "Error de conexión con el servidor, intente denuevo";
        }
        this.$buefy.dialog.alert({
          title: "Error",
          message: message,
          type: "is-danger",
          hasIcon: true,
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      downloadData() {
        const cell_ids = this.seqCells.map((x) => x.id);
        this.sheetGenerating = true;
        this.axios
          .post(
            routes.sequencingCellsListSheet,
            { ids: cell_ids },
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `CADIUMAG_planilla_celdas_secuenciación.xlsx`);
          })
          .catch(() => {
            this.errorAlert("Error al descargar datos, intente denuevo");
          })
          .finally(() => {
            this.sheetGenerating = false;
          });
      },
    },
  };
</script>

<style scoped>
  .table {
    margin-top: 1rem;
  }
</style>
