<template>
  <div class="container mt-5">
    <div>
      <h1 class="title">Ingreso de Resultados</h1>
    </div>

    <div class="columns is-centered">
      <b-loading :active="loading" :is-full-page="false" />
      <div class="column is-half">
        <input-form
          title=""
          form-id="results-form"
          :submit-url="routes.inputResults"
          :default-values="{ datetime: new Date(), files: {} }"
        >
          <template slot-scope="data">
            <label class="label">Equipo</label>
            <b-field
              v-for="(display, equipment) of equipmentOptions"
              :key="equipment"
            >
              <b-radio
                v-model="selectedEquipment"
                :native-value="equipment"
                name="equipment"
                type="is-primary"
              >
                <span>{{ display }}</span>
              </b-radio>
            </b-field>

            <label class="label">Kit</label>
            <b-field v-for="kit in kits" :key="kit.key">
              <b-radio
                v-model="selectedKit"
                :native-value="kit.key"
                name="kit"
                type="is-primary"
              >
                <span>{{ kit.display }}</span>
                <b-tooltip type="is-light" label="Información del kit">
                  <b-button
                    size="is-small"
                    type="is-text"
                    class="px-2 py-0 h20"
                    @click="showKitInfo(kit)"
                  >
                    <b-icon
                      type="is-primary"
                      icon="information-outline"
                      size="is-small"
                    />
                  </b-button>
                </b-tooltip>
              </b-radio>
            </b-field>

            <template v-if="selectedEquipment === 'lc480ii'">
              <label class="label">Archivos</label>
              <b-field
                v-for="reporter in selectedKitReporters"
                :key="reporter"
                class="file is-light"
              >
                <b-upload
                  v-model="data.values.files[reporter]"
                  :name="`${reporter.replace(' ', '')}_file`"
                  class="file-label"
                  accept=".txt"
                >
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label">{{ reporter }} (.txt)</span>
                  </span>

                  <span v-if="data.values.files[reporter]" class="file-name">
                    {{ data.values.files[reporter].name }}
                  </span>
                </b-upload>
              </b-field>
            </template>

            <template
              v-else-if="
                ['cfx96', 'abi7500', 'abi7500qs'].includes(selectedEquipment)
              "
            >
              <label class="label">Archivo</label>
              <b-field class="file is-light">
                <b-upload
                  v-model="data.values.file"
                  name="file"
                  class="file-label"
                  accept=".xls,.xlsx"
                >
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span>
                      {{ Object.values(selectedKitReporters).join(" + ") }}
                      (.xls, .xlsx)
                    </span>
                  </span>

                  <span v-if="data.values.file" class="file-name">
                    {{ data.values.file.name }}
                  </span>
                </b-upload>
              </b-field>
            </template>

            <b-field label="Fecha / Hora">
              <b-datetimepicker
                v-model="data.values.datetime"
                name="datetime"
                placeholder="Seleccione fecha / hora..."
                icon="calendar-today"
                :min-datetime="$date().add(-6, 'hours').toDate()"
                :max-datetime="new Date()"
                :datepicker="datepickerOptions"
                :timepicker="{ hourFormat: '24' }"
                trap-focus
              />
            </b-field>
          </template>
        </input-form>
      </div>
    </div>
  </div>
</template>

<script>
  import InputForm from "@/components/pcr-validation/InputForm.vue";
  import { routes } from "@/api";
  import { datepickerOptions } from "@/utils";
  import KitInfoModal from "@/components/pcr-validation/KitInfoModal.vue";

  export default {
    name: "DataInput",
    components: { InputForm },
    data() {
      return {
        routes: routes,
        equipmentOptions: {
          lc480ii: "LightCycler 480 II",
          cfx96: "Bio-Rad CFX96",
          abi7500: "ABI 7500 Fast",
          abi7500qs: "ABI 7500 Fast (QuantStudio)",
        },
        selectedEquipment: null,
        selectedKit: null,
        kits: [],
        datepickerOptions,
        loading: false,
      };
    },
    computed: {
      kitReporters() {
        return this.kits.reduce(
          (o, v) => ({
            ...o,
            [v.key]: [
              ...new Set(
                Object.values(v.targets)
                  .flat()
                  .map((x) => x.reporter)
              ),
            ],
          }),
          {}
        );
      },
      selectedKitReporters() {
        return this.selectedKit ? this.kitReporters[this.selectedKit] : [];
      },
    },
    created() {
      this.selectedEquipment = "lc480ii";
      this.loadKits();
    },
    methods: {
      loadKits() {
        this.loading = true;
        this.axios.get(routes.getKits).then((response) => {
          this.kits = response.data.results;
          this.selectedKit = this.kits[0].key;
          this.loading = false;
        });
      },
      showKitInfo(kit) {
        this.$buefy.modal.open({
          parent: this,
          component: KitInfoModal,
          props: {
            kitInfo: kit,
          },
          hasModalCard: true,
          trapFocus: true,
        });
      },
    },
  };
</script>

<style scoped>
  .h20 {
    height: 20px;
  }
</style>
