<script>
  import { routes } from "@/api";
  import { datepickerOptions } from "@/utils";
  import NonCovidSamplesList from "@/components/samples/NonCovidSamplesList.vue";

  export default {
    name: "SearchNonCovidByDate",
    components: { NonCovidSamplesList },
    data() {
      return {
        dateFrom: this.$date().subtract(6, "days").toDate(),
        dateTo: new Date(),
        sampleCategory: "all",
        sampleStatus: "all",
        searching: false,
        samplesData: undefined,
        datepickerOptions,
        sampleTypeOptions: [
          { value: "all", label: "Todas" },
          { value: "diagnosis", label: "Diagnóstico" },
          { value: "research", label: "Investigación" },
        ],
        sampleStatusOptions: [
          { value: "all", label: "Todos" },
          { value: "received", label: "Recepcionado" },
          { value: "processed", label: "Procesado" },
          { value: "rejected", label: "Rechazado" },
        ],
      };
    },
    methods: {
      searchSamples() {
        this.searching = true;
        this.axios
          .get(routes.searchNonCovidSamples, {
            params: {
              from: this.$date(this.dateFrom).format("YYYY-MM-DD"),
              to: this.$date(this.dateTo).format("YYYY-MM-DD"),
              sample_category: this.sampleCategory,
              sample_status: this.sampleStatus,
            },
          })
          .then((response) => {
            this.samplesData = response.data.results;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.searching = false;
          });
      },
    },
  };
</script>

<template>
  <section>
    <div class="columns is-centered">
      <div class="column is-9">
        <p class="has-text-grey">
          Esta página permite buscar muestras (no COVID-19) por fecha de
          recepción.
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half is-offset-one-quarter">
        <form @submit.prevent>
          <b-field grouped position="is-centered">
            <b-field label="Tipo de Muestra">
              <b-select v-model="sampleCategory">
                <option
                  v-for="option in sampleTypeOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Estado">
              <b-select v-model="sampleStatus">
                <option
                  v-for="option in sampleStatusOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Desde">
              <b-datepicker
                v-model="dateFrom"
                icon="calendar-today"
                :first-day-of-week="1"
                :day-names="datepickerOptions.dayNames"
                :month-names="datepickerOptions.monthNames"
              ></b-datepicker>
            </b-field>
            <b-field label="Hasta">
              <b-datepicker
                v-model="dateTo"
                icon="calendar-today"
                :first-day-of-week="1"
                :day-names="datepickerOptions.dayNames"
                :month-names="datepickerOptions.monthNames"
                trap-focus
              ></b-datepicker>
            </b-field>
            <b-field>
              <template slot="label"><br /></template>
              <b-button
                type="is-primary"
                @click="searchSamples"
                :loading="searching"
              >
                Buscar
              </b-button>
            </b-field>
          </b-field>
        </form>
      </div>
    </div>
    <div v-if="samplesData && samplesData.length > 0">
      <non-covid-samples-list :searching="searching" :samples="samplesData" />
    </div>
    <div
      v-else-if="samplesData && samplesData.length === 0"
      class="has-text-centered"
    >
      <span class="has-text-danger">
        No se encontró ningún resultado para el criterio utilizado.
      </span>
    </div>
  </section>
</template>

<style scoped></style>
