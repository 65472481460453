<template>
  <section>
    <div class="columns">
      <div class="column column-bottom">
        <b-checkbox v-model="checkboxAll" @input="toggleSelectAll">
          Seleccionar todas las muestras
        </b-checkbox>
      </div>
      <div class="column">
        <div class="has-text-right is-bottom">
          <b-checkbox v-model="cutoffEnabled">
            Evaluar punto de corte
          </b-checkbox>
          <b-field v-if="cutoffEnabled" grouped position="is-right">
            <b-field>
              <span class="label mt-1 mr-2"> Canal </span>
              <b-field>
                <b-radio-button
                  v-for="channel in channels"
                  :key="channel"
                  v-model="cutoffChannel"
                  :native-value="channel"
                >
                  {{ channel }}
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field>
              <span class="label mt-1 mr-2"> Ciclo </span>
              <b-numberinput
                v-model="cutoffValue"
                step="0.5"
                controls-position="compact"
                class="number-input-150"
              />
            </b-field>
          </b-field>
        </div>
      </div>
    </div>
    <div class="table-container">
      <b-table :data="tableData" :row-class="() => 'has-text-vcentered'">
        <b-table-column
          v-slot="props"
          :width="20"
          header-class="is-borderless"
          cell-class="has-text-vcentered is-borderless"
        >
          <div class="th-wrap">
            <strong>{{ props.row.name }}</strong>
          </div>
        </b-table-column>
        <b-table-column
          v-for="col in grid.columns"
          v-slot="props"
          :key="col"
          :label="String(col)"
          centered
          header-class="is-borderless"
          cell-class="chb-btn is-borderless"
        >
          <big-b-checkbox-button
            v-model="selected"
            :native-value="props.row[col].sample"
            :disabled="isEmpty(props.row[col])"
            :class="{ 'check-btn': !isEmpty(props.row[col]) }"
            :type="buttonTypes[props.row[col].sample]"
          >
            <div v-if="props.row[col].sample" class="check-btn-text">
              <p class="cell-sample-text">
                {{ props.row[col].sample }}
              </p>
              <p class="cell-ct-text mt-1">
                {{ targetNames[props.row[col].sample].FAM }} :
                <template v-if="props.row[col].FAM">
                  {{ props.row[col].FAM }}
                </template>
                <template v-else>-</template>
              </p>
              <p class="cell-ct-text">
                {{ targetNames[props.row[col].sample].VIC }} :
                <template v-if="props.row[col].VIC">
                  {{ props.row[col].VIC }}
                </template>
                <template v-else>-</template>
              </p>
            </div>
            <template v-else>-</template>
          </big-b-checkbox-button>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
  import BigBCheckboxButton from "@/components/inputs/BigBCheckboxButton.vue";
  export default {
    name: "QpcrSampleTable",
    components: { BigBCheckboxButton },
    props: {
      sampleData: {
        type: Array,
        required: true,
      },
      targetNames: {
        type: Object,
        required: true,
      },
      selectedSamples: {
        type: Array,
        default: () => [],
      },
      grid: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selected: this.$props.selectedSamples,
        checkboxAll: false,
        cutoffEnabled: false,
        cutoffValue: 37,
        cutoffChannel: "FAM",
      };
    },
    computed: {
      channels() {
        return [...new Set(this.sampleData.map((x) => x.reporter))];
      },
      ctByWell() {
        return this.sampleData.reduce((o, v) => {
          if (!o[v.well]) {
            o[v.well] = { sample: v.sample, [v.reporter]: {} };
          }
          o[v.well][v.reporter] = v.cq;
          return o;
        }, {});
      },
      tableData() {
        if (this.$props.grid.rows) {
          return this.$props.grid.rows.map((r) => {
            return this.$props.grid.columns.reduce((o, c) => {
              if (!o.name) {
                o.name = r;
              }
              const well = `${r}${c}`;
              if (Object.keys(this.ctByWell).includes(well)) {
                o[c] = this.ctByWell[well];
              } else {
                o[c] = {};
              }
              return o;
            }, {});
          });
        } else {
          return [];
        }
      },
      ctValues() {
        return this.sampleData.reduce((o, x) => {
          if (!o[x.sample]) {
            o[x.sample] = {};
          }
          o[x.sample][x.reporter] = x.cq;

          return o;
        }, {});
      },
      buttonTypes() {
        return Object.entries(this.ctValues).reduce((o, [k, v]) => {
          if (this.cutoffEnabled) {
            if (
              v[this.cutoffChannel] &&
              v[this.cutoffChannel] <= this.cutoffValue
            ) {
              o[k] = "is-danger";
            } else {
              o[k] = "is-success";
            }
          } else {
            o[k] = "is-primary";
          }

          return o;
        }, {});
      },
    },
    watch: {
      selectedSamples(val) {
        this.selected = val;
        if (this.selected.length !== Object.keys(this.ctByWell).length) {
          this.checkboxAll = false;
        }
      },
      selected(val) {
        this.$emit("update:selected-samples", val);
      },
    },
    methods: {
      toggleSelectAll() {
        if (this.selected.length === Object.keys(this.ctByWell).length) {
          this.selected = [];
        } else {
          this.selected = Object.values(this.ctByWell).map((x) => x.sample);
        }
      },
      isEmpty(obj) {
        return Object.keys(obj).length === 0;
      },
    },
  };
</script>

<style scoped>
  .number-input-150 {
    max-width: 150px;
  }

  .column-bottom {
    margin-top: auto;
  }

  .cell-sample-text {
    font-size: 0.7rem;
    font-weight: 600;
  }

  .cell-ct-text {
    font-size: 0.6rem;
  }

  .check-btn-text {
    pointer-events: none;
  }
</style>

<style lang="scss">
  .chb-btn {
    padding: 6px 0 0 2px !important;
  }

  .check-btn {
    :hover {
      transform: scale(1.25);
      z-index: 99;
    }
  }
</style>
