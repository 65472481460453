<script>
  import { formatDateTime } from "@/utils";
  import { routes } from "@/api";

  export default {
    name: "ViewNonCovidSampleModal",
    props: {
      sampleId: {
        type: [Number, String],
        required: true,
      },
    },
    data() {
      return {
        sample: {},
        sampleEvents: [],
      };
    },
    mounted() {
      this.loadSampleDetails();
      this.loadSampleEvents();
    },
    methods: {
      loadSampleDetails() {
        this.axios
          .get(routes.getNonCovidSampleDetails.replace(":id", this.sampleId))
          .then((response) => {
            this.sample = response.data.result;
          });
      },
      loadSampleEvents() {
        this.axios
          .get(routes.getNonCovidSampleEvents.replace(":id", this.sampleId))
          .then((response) => {
            this.sampleEvents = response.data.result;
          });
      },
      formatDateTime,
      formatStatus(sample) {
        if (sample.conclusion === "RECHAZADO") {
          return "Rechazado";
        } else {
          return sample.status;
        }
      },
    },
  };
</script>

<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Muestra {{ sampleId }}</p>
    </header>
    <section class="modal-card-body">
      <div class="columns">
        <div class="column">
          <h3 class="subtitle">Muestra</h3>
          <table class="table is-fullwidth is-narrow two-cols">
            <tbody>
              <tr>
                <th>ID</th>
                <td>{{ sample.sampleId }}</td>
              </tr>
              <tr>
                <th>Estado</th>
                <td>{{ formatStatus(sample) }}</td>
              </tr>
              <tr>
                <th>Prioridad</th>
                <td>{{ sample.priority }}</td>
              </tr>
              <tr>
                <th>Categoría</th>
                <td>{{ sample.sampleCategory }}</td>
              </tr>
              <tr>
                <th>Institución</th>
                <td>{{ sample.institution }}</td>
              </tr>
              <tr v-if="sample.sampleOrigin !== ''">
                <th>Procedencia</th>
                <td>{{ sample.sampleOrigin }}</td>
              </tr>
              <tr>
                <th>Examen</th>
                <td>{{ sample.examType }}</td>
              </tr>
              <tr>
                <th>Médico Solicitante</th>
                <td>{{ sample.requestedBy }}</td>
              </tr>
              <tr>
                <th>Tipo</th>
                <td>{{ sample.sampleType }}</td>
              </tr>
              <tr v-if="sample.conclusionDetails !== ''">
                <th>Detalles</th>
                <td>{{ sample.conclusionDetails }}</td>
              </tr>
              <tr>
                <th>Toma de Muestra</th>
                <td>{{ formatDateTime(sample.samplingDatetime, true) }}</td>
              </tr>
              <tr>
                <th>Recepción</th>
                <td>{{ formatDateTime(sample.receptionDatetime, true) }}</td>
              </tr>
              <tr v-if="sample.processingDatetime !== ''">
                <th>Procesamiento</th>
                <td>{{ formatDateTime(sample.processingDatetime, true) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="column">
          <h3 class="subtitle">Paciente</h3>
          <table class="table is-fullwidth is-narrow two-cols">
            <tbody>
              <tr>
                <th>RUN</th>
                <td>{{ sample.patientIdString }}</td>
              </tr>
              <tr>
                <th>Nombre</th>
                <td>
                  {{ sample.patientNames }} {{ sample.patientFirstLastname }}
                  {{ sample.patientSecondLastname }}
                </td>
              </tr>
              <tr>
                <th>Sexo</th>
                <td>
                  {{ sample.patientSex }}
                </td>
              </tr>
              <tr>
                <th>Edad</th>
                <td>
                  {{ sample.patientAge }}
                </td>
              </tr>
              <tr>
                <th>Teléfono</th>
                <td>
                  {{ sample.patientPhone }}
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  {{ sample.patientEmail }}
                </td>
              </tr>
              <tr>
                <th>Dirección</th>
                <td>
                  {{ sample.patientAddress }}
                </td>
              </tr>
            </tbody>
          </table>

          <template v-if="sampleEvents.length > 0">
            <h3 class="subtitle">Eventos</h3>
            <table class="table is-fullwidth is-narrow">
              <thead>
                <tr>
                  <th>Fecha / Hora</th>
                  <th>Evento</th>
                  <th>Usuario</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(event, i) in sampleEvents" :key="`event${i}`">
                  <td>{{ formatDateTime(event.datetime, true) }}</td>
                  <td>{{ event.eventType }}</td>
                  <td>{{ event.user }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button class="mx-auto" @click="$parent.close()">Cerrar</b-button>
    </footer>
  </div>
</template>

<style scoped>
  table.two-cols {
    tr {
      th {
        width: 40%;
      }
    }
  }

  .modal-card {
    width: 100%;
  }
</style>
