<template>
  <div class="container mt-5">
    <h1 class="title">Placas de PCR</h1>
    <div id="filterBar" class="container mb-5">
      <pcr-plates-filter-bar>
        <template #default="{ filters }">
          <b-button type="is-primary" @click="loadData(filters)"
            >Filtrar Datos
          </b-button>
        </template>
        <template #reset="{ cleanInput }">
          <b-button class="ml-2" type="is-danger" @click="cleanInput"
            >Limpiar filtros
          </b-button>
        </template>
      </pcr-plates-filter-bar>
    </div>
    <div class="columns is-centered">
      <b-button
        v-if="recordNumber > 0"
        type="is-primary"
        icon-left="file-excel"
        :loading="sheetGenerating"
        @click="downloadData()"
      >
        Descargar planilla de placas
      </b-button>
    </div>
    <div class="table">
      <b-table
        :data="pcrPlates"
        :loading="loading"
        checkbox-position="left"
        sortable
        paginated
        :per-page="perPage"
        :current-page.sync="currentPage"
      >
        <b-table-column
          v-slot="{ row }"
          label="Nº registro"
          field="id"
          cell-class="valign-mid"
          centered
          sortable
          numeric
        >
          {{ row.id }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Placa mapeo"
          field="idString"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.idString }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Operador"
          field="operator"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.operator }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Equipo"
          field="pcrEquipment"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.pcrEquipment }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Kit"
          field="pcrKit"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.pcrKit }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Fecha"
          field="startDatetime"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ formatDatetime(row.startDatetime) }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Muestras"
          field="samples"
          cell-class="valign-mid"
          centered
          sortable
        >
          {{ row.samples }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label=""
          cell-class="valign-mid"
          centered
        >
          <b-tooltip label="Detalles" type="is-light">
            <router-link
              :to="{ name: 'PcrPlateDetails', params: { id: row.id } }"
            >
              <b-icon icon="information" type="is-primary" />
            </router-link>
          </b-tooltip>
        </b-table-column>

        <b-table-column
          v-slot="{ row }"
          label="Acciones"
          field="actions"
          cell-class="valign-mid"
          centered
        >
          <b-tooltip
            label="Enviar a PCR muestras de la placa en extracción"
            type="is-light"
          >
            <b-button
              v-if="inExtractionSamples[row.id]"
              type="is-primary"
              icon-left="check-bold"
              @click="updateSamples(row.id)"
            >
              Confirmar PCR
            </b-button>
          </b-tooltip>
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Eliminar"
          field="delete"
          cell-class="valign-mid"
          centered
        >
          <b-tooltip label="Eliminar" type="is-danger">
            <b-button
              v-if="inExtractionSamples[row.id]"
              type="is-text"
              @click="deleteDialog(row.id, row.idString)"
            >
              <b-icon
                class="icon"
                icon="delete"
                custom-size="mdi-24px"
                type="is-danger"
              />
            </b-button>
          </b-tooltip>
        </b-table-column>
        <template #empty><div class="table-empty" /></template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import UpdatePcrPlateSamplesModal from "@/components/plates/UpdatePcrPlateSamplesModal.vue";
  import PcrPlatesFilterBar from "@/components/plates/PcrPlatesFilterBar.vue";

  export default {
    name: "PcrPlatesList",
    components: {
      PcrPlatesFilterBar,
    },
    data() {
      return {
        loading: true,
        loadingOptions: false,
        sheetGenerating: false,
        currentPage: 1,
        perPage: 20,
        filterOptions: {},
        pcrPlates: [],
        inExtractionSamples: [],
      };
    },
    computed: {
      recordNumber() {
        return this.pcrPlates.length;
      },
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData(filters = null) {
        this.loading = true;
        this.axios
          .post(routes.listPcrPlates, { filters: filters })
          .then((response) => {
            if (response.data.status === "OK") {
              this.pcrPlates = response.data.pcrPlates;
              this.inExtractionSamples = response.data.inExtractionSamples;
            } else {
              this.errorAlert(response.data.message);
            }
          })
          .catch(() => {
            this.errorAlert();
          })
          .finally(() => {
            this.loading = false;
          });
      },
      formatDatetime(datetime) {
        return this.$date(datetime).format("DD/MM/YYYY");
      },
      errorAlert(message = null) {
        if (!message) {
          message = "Error de conexión con el servidor, intente denuevo";
        }
        this.$buefy.dialog.alert({
          title: "Error",
          message: message,
          type: "is-danger",
          hasIcon: true,
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      deleteDialog(id, name) {
        this.$buefy.dialog.confirm({
          title: "Eliminar placa de PCR",
          message: `Esta seguro de <b>eliminar</b> la placa de extracción
        <b>${name}</b>? Tiene la opción de volver a subir la misma información en el futuro`,
          confirmText: "Eliminar",
          cancelText: "Cancelar",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => {
            this.deletePlate(id);
          },
        });
      },
      deletePlate(id) {
        this.axios
          .post(routes.deletePcrPlate.replace(":id", id))
          .then((response) => {
            if (response.data.status === "OK") {
              this.$buefy.toast.open({
                type: "is-success",
                duration: 2000,
                message: `Placa eliminada`,
              });
              this.loadData();
            } else {
              this.errorAlert(response.data.message);
            }
          })
          .catch(() => {
            this.errorAlert();
          });
      },
      updateSamples(plateId) {
        this.$buefy.modal.open({
          parent: this,
          component: UpdatePcrPlateSamplesModal,
          props: {
            samples: this.inExtractionSamples[plateId],
          },
          events: {
            saved: (modal) => {
              modal.close();
              this.loadData();
            },
          },
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
        });
      },
      downloadData() {
        const plate_ids = this.pcrPlates.map((x) => x.id);
        this.sheetGenerating = true;
        this.axios
          .post(
            routes.pcrPlatesListSheet,
            { ids: plate_ids },
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `CADIUMAG_planilla_placas_pcr.xlsx`);
          })
          .catch(() => {
            this.errorAlert("Error al descargar datos, intente denuevo");
          })
          .finally(() => {
            this.sheetGenerating = false;
          });
      },
    },
  };
</script>

<style scoped>
  .table {
    margin-top: 1rem;
  }
</style>
