<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Recepción de Muestras</p>
      </header>
      <section class="modal-card-body">
        <p class="mb-4 has-text-grey">
          Estas muestras se registrarán como
          <strong>recepcionadas</strong> en la plataforma de Toma de Muestras.
          Posteriormente, se cambiará su estado de "Pre-Recepcionado" a "En
          Extracción".
        </p>
        <div class="mb-3">
          <strong> Muestras: </strong>
          <div class="content">
            <ul class="two-cols">
              <li v-for="sample in samples" :key="sample.sampleId">
                {{ sample.sampleId }} (Ext. {{ sample.externalId }})
              </li>
            </ul>
          </div>
        </div>

        <messages-display v-if="hasFailed" :status-messages="statusMessages" />
      </section>
      <footer class="modal-card-foot">
        <template v-if="!submitted">
          <b-button :disabled="saving" @click="$parent.close()">
            Cancelar
          </b-button>
          <b-button type="is-success" :loading="saving" @click="receiveSamples">
            Recepcionar Muestras
          </b-button>
        </template>
        <template v-else>
          <b-button :disabled="saving" @click="$parent.close()">
            Cerrar
          </b-button>
        </template>
      </footer>
    </div>
  </form>
</template>

<script>
  import { routes } from "@/api";
  import MessagesDisplay from "@/components/MessagesDisplay.vue";

  export default {
    name: "ReceiveSamplesModal",
    components: { MessagesDisplay },
    props: {
      samples: {
        type: Array,
        required: true,
      },
      batchId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        statusMessages: [],
        saving: false,
        submitted: false,
      };
    },
    computed: {
      hasFailed() {
        return this.statusMessages.some((x) => x.status !== "OK");
      },
    },
    methods: {
      receiveSamples() {
        this.saving = true;
        this.statusMessages = [];
        this.axios
          .post(routes.receiveSamplesSSM, {
            samples: this.samples.map((x) => x.externalId),
            batchId: this.batchId,
          })
          .then((response) => {
            this.submitted = true;

            if (response.data.status === "OK") {
              this.$buefy.toast.open({
                duration: 2000,
                type: "is-success",
                message: "Muestras recepcionadas correctamente.",
              });

              this.$emit("saved", this.$parent);
            } else if (response.data.status === "warning") {
              if (response.data.results.OK.length > 0) {
                this.statusMessages.push({
                  origin: "Toma de Muestras",
                  status: "OK",
                  message: response.data.results.OK.join(", "),
                });
              }

              this.statusMessages.push({
                origin: "Toma de Muestras",
                status: "error",
                message: response.data.results.errors,
              });

              if (response.data.results.OK.length > 0) {
                this.$emit("saved", this.$parent, false);
              }
            } else {
              this.statusMessages.push({
                origin: "LIS",
                status: "error",
                message: response.data.message,
              });
              return Promise.reject(response.data.message);
            }
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.saving = false;
          });
      },
    },
  };
</script>

<style scoped>
  ul.two-cols {
    columns: 2;
  }
</style>
