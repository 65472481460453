<template>
  <div class="container mt-5">
    <h1 class="title">
      Muestras Pendientes ({{ statusTexts[$route.params.status] }})
    </h1>

    <p class="has-text-grey mb-4">
      Esta sección permite visualizar las muestras que aún no han sido
      analizadas.
    </p>

    <div class="columns place-center">
      <div class="column">
        <b-table
          v-if="samples.length > 0 || loading"
          :data="formattedData"
          :loading="loading"
          hoverable
          narrowed
        >
          <b-table-column v-slot="props" label="#" centered>
            {{ props.index + 1 }}
          </b-table-column>
          <b-table-column v-slot="props" label="Muestra" centered>
            {{ props.row.sampleId }}
            <span v-if="props.row.repetition === 'Sí'">[R]</span>
          </b-table-column>
          <b-table-column v-slot="props" label="N° Pet. (Ex)" centered>
            {{ props.row.externalId }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="institution"
            label="Institución"
            centered
          >
            {{ props.row.institution }}
          </b-table-column>
          <b-table-column v-slot="props" label="RUN / Pas." centered>
            {{ props.row.idString }}
          </b-table-column>
          <b-table-column v-slot="props" label="Nombre" centered>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column v-slot="props" label="Ingreso" centered>
            {{ formatDateTime(props.row.receptionDatetime) }}
          </b-table-column>
          <template slot="empty"><div class="table-empty" /></template>
        </b-table>
        <b-message v-else> No hay resultados para mostrar. </b-message>
      </div>
    </div>
    <section v-if="samples.length > 0" class="mt-5 has-text-centered">
      <b-field>
        <b-button class="is-primary" icon-left="content-save">
          <download-csv
            :data="formattedData"
            :separator-excel="excelCompatible"
            :fields="Object.keys(columnLabels)"
            :labels="columnLabels"
            :name="`pending_samples_${$date().format('YYYYMMDD_HHmm')}.csv`"
          >
            Descargar (.csv)
          </download-csv>
        </b-button>
      </b-field>
      <b-field>
        <b-checkbox v-model="excelCompatible">
          Hacer compatible con MS Excel
        </b-checkbox>
      </b-field>
    </section>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime, hasPermission } from "@/utils";

  export default {
    name: "ReportPendingSamples",
    data() {
      return {
        samples: [],
        loading: false,
        excelCompatible: false,
        columnLabels: {
          sampleId: "Muestra",
          externalId: "N° Pet. (Externo)",
          institution: "Institución",
          idString: "RUN",
          name: "Nombre",
          samplingDatetimeFmt: "Toma de Muestra",
          receptionDatetimeFmt: "Recepción",
          repetition: "Repetición",
        },
        statusTexts: {
          "in-extraction": "En Extracción",
          "in-pcr": "En PCR",
        },
      };
    },
    computed: {
      formattedData() {
        return this.samples.map((x) => {
          return {
            ...x,
            name: `${x.names} ${x.firstLastname} ${x.secondLastname}`,
            externalId: x.externalId
              ? `${x.institutionPrefix}${x.externalId}`
              : null,
            samplingDatetimeFmt: this.$date(x.samplingDatetime).format(
              "DD-MM-YYYY HH:mm"
            ),
            receptionDatetimeFmt: this.$date(x.receptionDatetime).format(
              "DD-MM-YYYY HH:mm"
            ),
            repetition: x.repetition ? "Sí" : "No",
          };
        });
      },
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.axios
          .get(
            routes.pendingSamples.replace(":status", this.$route.params.status)
          )
          .then((response) => {
            this.samples = response.data.results;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      hasPermission(routeName) {
        return hasPermission(routeName);
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime);
      },
    },
  };
</script>

<style scoped>
  .place-center {
    place-content: center;
  }
</style>
