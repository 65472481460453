<template>
  <div class="container mt-4">
    <b-loading v-model="loading" is-full-page></b-loading>
    <h1 class="title">
      Detalles placa PCR Nº{{ plateId }}
      <b-button type="is-light" @click="openEditModal">
        <b-icon type="is-dark" icon="pen"></b-icon>
      </b-button>
    </h1>
    <div class="columns is-mobile is-centered">
      <div class="column is-two-thirds-fullhd is-four-fifths-desktop">
        <div>
          <table class="table is-fullwidth mb-0">
            <thead>
              <tr>
                <th colspan="4"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="valign-mid">
                  <strong>Placa mapeo:</strong>
                </td>
                <td class="valign-mid">{{ plateData.idString }}</td>
                <td class="valign-mid">
                  <strong>Total muestras:</strong>
                </td>
                <td class="valign-mid">{{ plateData.samples }}</td>
              </tr>
              <tr>
                <td class="valign-mid">
                  <strong>Lote:</strong>
                </td>
                <td class="valign-mid">{{ plateData.batch }}</td>
                <td class="valign-mid">
                  <strong>Vencimiento:</strong>
                </td>
                <td class="valign-mid">
                  {{ getDate(plateData.expirationDatetime) }}
                </td>
              </tr>
              <tr>
                <td class="valign-mid">
                  <strong>Fecha:</strong>
                </td>
                <td class="valign-mid">
                  {{ getDate(plateData.startDatetime) }}
                </td>
                <td class="valign-mid">
                  <strong>Equipo:</strong>
                </td>
                <td class="valign-mid">{{ plateData.pcrEquipment }}</td>
              </tr>
              <tr>
                <td class="valign-mid">
                  <strong>Hora de inicio:</strong>
                </td>
                <td class="valign-mid">
                  {{ getTime(plateData.startDatetime) }}
                </td>
                <td class="valign-mid">
                  <strong>Kit:</strong>
                </td>
                <td class="valign-mid">{{ plateData.pcrKit }}</td>
              </tr>
              <tr>
                <td class="valign-mid">
                  <strong>Hora de termino:</strong>
                </td>
                <td class="valign-mid">
                  {{ getTime(plateData.finishDatetime) }}
                </td>
                <td class="valign-mid">
                  <strong>NTC válido:</strong>
                </td>
                <td class="valign-mid">{{ bool2str[plateData.ntcValid] }}</td>
              </tr>
              <tr>
                <td class="valign-mid">
                  <strong>Control positivo válido:</strong>
                </td>
                <td class="valign-mid">
                  {{ bool2str[plateData.positiveControlValid] }}
                </td>
                <td class="valign-mid">
                  <strong>Control negativo válido:</strong>
                </td>
                <td class="valign-mid">
                  {{ bool2str[plateData.negativeControlValid] }}
                </td>
              </tr>
              <tr>
                <td class="valign-mid">
                  <strong>Operador:</strong>
                </td>
                <td class="valign-mid">{{ plateData.operator }}</td>
                <td class="valign-mid">
                  <strong>Revisor:</strong>
                </td>
                <td class="valign-mid">{{ plateData.reviewer }}</td>
              </tr>
              <tr></tr>
            </tbody>
          </table>

          <p v-if="plateData.observations" class="mt-2">
            <strong class="has-text-success">Observaciones:</strong>
            {{ plateData.observations }}
          </p>
          <p v-if="plateData.incidents" class="mt-2">
            <strong class="has-text-danger-dark">Incidentes:</strong>
            {{ plateData.incidents }}
          </p>
        </div>
      </div>
    </div>
    <p class="has-text-grey mb-2">Tabla con mapeo de muestras:</p>
    <div>
      <plate-table :table-data="tableData" />
    </div>
    <div class="columns is-centered mt-5">
      <b-button
        type="is-primary"
        icon-left="file-excel"
        :loading="sheetGenerating"
        @click="downloadData()"
      >
        Descargar datos de mapeo</b-button
      >
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import PlateTable from "@/components/plates/PlateLayoutTable.vue";
  import PcrPlateEditModal from "@/components/plates/PcrPlateEditModal.vue";

  export default {
    name: "PcrPlateDetails",
    components: { PlateTable },
    data() {
      return {
        sheetGenerating: false,
        loading: false,
        plateId: this.$route.params.id,
        plateData: {},
        tableData: [],
        bool2str: { true: "Si", false: "No" },
      };
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.axios
          .get(routes.pcrPlateDetails.replace(/:id/, this.plateId))
          .then((response) => {
            this.plateData = response.data.results.recordData;
            this.tableData = response.data.results.mapping;
          })
          .catch(() => {
            this.errorAlert();
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getDate(datetime) {
        return this.$date(datetime).format("DD/MM/YYYY");
      },
      getTime(datetime) {
        return this.$date(datetime).format("HH:mm");
      },
      errorAlert(message = null) {
        if (!message) {
          message = "Error de conexión con el servidor, intente denuevo";
        }
        this.$buefy.dialog.alert({
          title: "Error",
          message: message,
          type: "is-danger",
          hasIcon: true,
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      downloadData() {
        this.sheetGenerating = true;
        this.axios
          .get(routes.getPcrPlateSheet.replace(/:id/, this.plateId), {
            responseType: "blob",
          })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `CADIUMAG_placa_pcr_${this.plateId}.xlsx`);
          })
          .catch(() => {
            this.errorAlert();
          })
          .finally(() => {
            this.sheetGenerating = false;
          });
      },
      openEditModal() {
        this.$buefy.modal.open({
          parent: this,
          component: PcrPlateEditModal,
          props: {
            plateData: this.plateData,
          },
          events: {
            saved: (modal) => {
              this.loadData();
              modal.close();
              this.$buefy.toast.open({
                duration: 2000,
                message: "Cambios guardados correctamente",
                type: "is-success",
              });
            },
          },
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
        });
      },
    },
  };
</script>

<style scoped></style>
