<template>
  <div class="container mt-5">
    <h1 class="title">Análisis de qPCR</h1>

    <div class="mt-5">
      <h2 class="subtitle">Ingrese el archivo (.txt)</h2>
      <div class="columns">
        <div class="column">
          <b-field class="file">
            <b-upload v-model="file" accept=".txt" @input="loadSamples">
              <b-button
                tag="a"
                type="is-light"
                icon-left="upload"
                :disabled="loading"
              >
                Cargar Archivo
              </b-button>
            </b-upload>
            <span v-if="file" class="file-name">
              {{ file.name }}
            </span>
          </b-field>
        </div>
        <div v-if="metadata.length > 0" class="column has-text-right">
          <div class="field">
            <b-button class="is-primary" icon-left="content-save">
              <download-csv
                :data="metadata"
                :fields="Object.keys(metadata[0])"
                :name="`${file.name.split('.')[0]}_qPCR_results.csv`"
                :separator-excel="excelCompatible"
              >
                Descargar (.csv)
              </download-csv>
            </b-button>
            <b-button
              class="is-primary ml-2"
              icon-left="content-save"
              :loading="generatingCt"
              @click="getAutoCqFiles"
            >
              Descargar Ct automáticos (.csv)
            </b-button>
          </div>
          <div class="field">
            <b-checkbox v-model="excelCompatible">
              Hacer compatible con MS Excel
            </b-checkbox>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-quarter">
          <div
            v-for="[type, details] of Object.entries(messageTypes)"
            :key="type"
          >
            <b-message
              v-if="type in messages"
              :title="details.messageTitle"
              :type="details.messageType"
              aria-close-label="Cerrar mensaje"
            >
              <ul>
                <li v-for="(message, i) in messages[type]" :key="i">
                  {{ message }}
                </li>
              </ul>
            </b-message>
          </div>
        </div>
      </div>
    </div>

    <section>
      <b-loading
        v-if="loading"
        class="min-height-400px"
        :is-full-page="false"
        :active="loading"
      />

      <qpcr-analysis
        :raw-values="rawValues"
        :grid="grid"
        :metadata="metadata"
      />
    </section>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import QpcrAnalysis from "@/components/qpcr-analysis/QpcrAnalysis.vue";

  export default {
    name: "QpcrABI",
    components: {
      QpcrAnalysis,
    },
    data() {
      return {
        file: null,
        rawValues: {},
        metadata: [],
        grid: {
          columns: Array.from({ length: 12 }, (x, i) => i + 1),
          rows: [..."ABCDEFGH"],
        },
        loading: false,
        generatingCt: false,
        messages: {},
        messageTypes: {
          warnings: { messageTitle: "Advertencias", messageType: "is-warning" },
          errors: { messageTitle: "Errores", messageType: "is-danger" },
        },
        excelCompatible: false,
      };
    },
    computed: {},
    methods: {
      loadSamples() {
        this.loading = true;
        let formData = new FormData();
        formData.append("file", this.file);

        this.messages = {};
        this.axios
          .post(routes.parseABI, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.data.status !== "error") {
              this.rawValues = response.data.rawData;
              this.metadata = response.data.metadata.map((x) => ({
                ...x,
                cq: null,
                cqMean: null,
              }));
              this.grid = response.data.grid;
            } else {
              this.messages = { errors: ["Errores en el archivo"] };
              this.metadata = [];
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getAutoCqFiles() {
        this.generatingCt = true;
        this.axios
          .post(routes.calculateCt, {
            raw_data: this.rawValues,
            metadata: this.metadata,
            responseType: "blob",
          })
          .then((response) => {
            this.generatingCt = false;
            const data = this.excelCompatible
              ? "SEP=\n" + response.data
              : response.data;
            const blob = new Blob([data], {
              type: "text/csv",
            });
            saveAs(blob, `${this.file.name.split(".")[0]}_qPCR_automatic.csv`);
          });
      },
    },
  };
</script>

<style scoped>
  .min-height-400px {
    min-height: 400px;
  }
</style>
