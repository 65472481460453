<template>
  <b-navbar
    v-if="$store.state.isAuthenticated && $route.name !== 'Login'"
    type="is-light"
    wrapper-class="container"
  >
    <template #brand>
      <b-navbar-item href="https://catg.cl" title="cATG" target="_blank">
        <img
          id="navbar-logo"
          src="@/assets/catg_logo_notext.svg"
          alt="CATG logo"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <my-navbar-item route-name="Home" label="Inicio" />

      <my-navbar-dropdown
        label="Ingreso"
        :children="[
          {
            route: 'SamplesInput',
            label: 'Ingreso de Muestras no COVID',
          },
          {
            route: 'ReceivedSamplesSSM',
            label: 'Recepción de Muestras SSM',
          },
          { route: 'DataInput', label: 'Ingreso de Resultados' },
        ]"
      />

      <my-navbar-dropdown
        label="Muestras"
        :children="[
          { route: 'PendingNonCovidSamples', label: 'Pendientes no COVID' },
          { route: 'SearchNonCovidSamples', label: 'Buscar Muestras no COVID' },
          { divider: true },
          {
            route: 'PendingSamples',
            routeParams: { status: 'in-pcr' },
            label: 'En PCR',
          },
          {
            route: 'PendingSamples',
            routeParams: { status: 'in-extraction' },
            label: 'En Extracción',
          },
          { route: 'ResultsReview', label: 'Procesadas' },
          { divider: true },
          { route: 'SampleBatches', label: 'Lotes' },
          {
            route: 'LabelPrinting',
            label: 'Impresión de Etiquetas',
          },
          { route: 'ResultValidation', label: 'Validación' },
          { route: 'SamplePooling', label: 'Agrupamiento' },
        ]"
      />
      <my-navbar-dropdown
        label="Placas"
        :children="[
          {
            route: 'ExtractionPlateInput',
            label: 'Ingreso Placa Extracción',
          },
          {
            route: 'ExtractionPlatesList',
            label: 'Visualización / Gestión Extracción',
          },
          { divider: true },
          {
            route: 'PcrPlateInput',
            label: 'Ingreso Placa PCR',
          },
          {
            route: 'PcrPlatesList',
            label: 'Visualización / Gestión PCR',
          },
        ]"
      />
      <my-navbar-dropdown
        label="Secuenciación"
        :children="[
          {
            route: 'ImportExtSamples',
            label: 'Importación de Muestras',
          },
          { divider: true },
          {
            route: 'SeqCellInput',
            label: 'Ingreso Celda de Secuenciación',
          },
          {
            route: 'SeqCellsList',
            label: 'Visualización / Gestión Secuenciación',
          },
        ]"
      />
      <my-navbar-dropdown
        label="Informes"
        :children="[
          { route: 'UnreportedSamplesSSM', label: 'Reportar Muestras SSM' },
          { route: 'OnDemandReports', label: 'Libres' },
        ]"
      />
      <my-navbar-dropdown
        label="Análisis qPCR"
        :children="[
          { route: 'QpcrTrainingList', label: 'Entrenamiento' },
          { route: 'QpcrABI', label: 'qPCR ABI' },
        ]"
      />
    </template>

    <template #end>
      <my-navbar-dropdown
        label="Administración"
        :children="[{ route: 'ListUsers', label: 'Usuarios' }]"
      />
      <b-navbar-dropdown :label="$store.state.user.displayName" right>
        <b-navbar-item tag="router-link" :to="{ name: 'ChangePassword' }">
          Cambiar Contraseña
        </b-navbar-item>
        <b-navbar-item @click="logout"> Cerrar Sesión</b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
  import MyNavbarDropdown from "@/components/menus/MyNavbarDropdown.vue";
  import MyNavbarItem from "@/components/menus/MyNavbarItem.vue";

  export default {
    name: "MyHeader",
    components: { MyNavbarDropdown, MyNavbarItem },
    methods: {
      logout() {
        this.$store.dispatch("logout").then(() => {
          this.$router.push({ name: "Login" });
        });
      },
    },
  };
</script>

<style scoped></style>
