export const routes = {
  // samples
  inputSamples: "/samples/input",
  rejectSample: "/samples/reject",
  pendingSamples: "/samples/pending/:status",
  samplesForClosure: "/samples/closure/list",
  submitClosure: "/samples/closure/submit",
  updateSamplesStatus: "/samples/update-status",
  unreportedSamples: "/samples/unreported",
  searchSamples: "/samples/search",
  deleteSamples: "/samples/delete",
  listSamplesByBatch: "/samples/list-by-batch",
  inputNonCovidSamples: "/samples/non-covid/input",
  pendingNonCovidSamples: "/samples/non-covid/pending",
  processNonCovidSamples: "/samples/non-covid/process",
  rejectNonCovidSamples: "/samples/non-covid/reject",
  deleteNonCovidSamples: "/samples/non-covid/delete",
  searchNonCovidSamples: "/samples/non-covid/search",
  getNonCovidSampleDetails: "/samples/non-covid/:id/details",
  getNonCovidSampleEvents: "/samples/non-covid/:id/events",
  // sample batches
  listBatches: "/batches/list/:type",
  listBatchesForLabels: "/batches/list-for-labels",
  addBatchEvent: "/batches/add-event",
  batchDispatchDocument: "/batches/:id/dispatch-document",
  batchSamplesSheet: "/batches/:id/samples-sheet",
  batchEvents: "/batches/:id/events",
  batchSamples: "/batches/:id/samples",
  // sample pools
  createPools: "/pools/new",
  unvalidatedPools: "/pools/list/unvalidated",
  recentPools: "/pools/list/recent",
  samplesForPooling: "/pools/samples-available",
  deletePool: "/pools/delete/:id",
  // analyses
  visualizationList: "/analyses/list",
  inputResults: "/analyses/input",
  undoResultsInput: "/analyses/undo-input",
  listResultsToValidate: "/analyses/to-validate",
  validateResults: "/analyses/validate",
  // reports
  reportSamples: "/reports/generate-unreported",
  getConsolidated: "/reports/consolidated",
  getStatisticalReport: "/reports/statistics",
  dailySummary: "/reports/daily-summary",
  getIndividualReport: "/reports/individual",
  getPositiveReports: "/reports/positive-compiled",
  getLabels: "/reports/labels",
  massiveReport: "/reports/massive",
  // data
  dailyCounts: "/stats/daily-cases",
  getInstitutions: "/data/institutions",
  getKits: "/data/kits",
  getExamTypes: "/data/exam-types",
  getSampleCategories: "/data/sample-categories",
  getRejectionReasons: "/data/rejection-reasons",
  getUrgencyCriteria: "/data/urgency-criteria",
  patientInfo: "/data/patients/:id",
  // auth
  login: "/auth/login",
  logout: "/auth/logout",
  passwordChange: "/auth/change-password",
  getUsers: "/auth/users/list",
  createUser: "/auth/users/new",
  getUser: "/auth/users/:id/info",
  updateUser: "/auth/users/:id/edit",
  getUsernames: "/auth/usernames",
  getRoles: "/auth/roles",
  // others
  parseSheet: "/utils/parse-sheet",
  sampleInputTemplate: "/docs/sample-input-template",
  sampleInputInstructive: "/docs/sample-input-instructive",
  dummyCheck: "/utils/dummy-check",
  // qpcr
  parseABI: "/qpcr/parse-abi-file",
  calculateCt: "/qpcr/calculate-ct",
  listPCRFiles: "/qpcr/list-pcr-files",
  getExperimentInfo: "/qpcr/experiments/:id/info",
  getExperimentData: "/qpcr/experiments/:id/raw-data",
  saveExperimentValidation: "/qpcr/experiments/:id/save-validation",
  downloadExperimentValidations: "/qpcr/experiments/:id/download-validations",
  // integrations
  getReceivedSamplesSSM: "/integrations/ssm/received-samples",
  preReceiveSamplesSSM: "/integrations/ssm/pre-receive-samples",
  receiveSamplesSSM: "/integrations/ssm/receive-samples",
  rejectionOptionsSSM: "/integrations/ssm/rejection-options",
  rejectSampleSSM: "/integrations/ssm/reject-sample",
  getUnreportedSamplesSSM: "/integrations/ssm/unreported-samples",
  sendResultsSSM: "/integrations/ssm/send-results",
  importSamples: "/integrations/ssm/import-samples",
  // plates
  standardPlateFileValidator: "/plates/file-validation/standard",
  seqCellInputTemplate: "/docs/seq-plate-input-template",
  plateLayoutInputTemplate: "/docs/plate-input-template",
  // extraction plates
  getExtractionPlateOptions: "/plates/extraction/options",
  inputExtractionPlateLayout: "/plates/input/extraction",
  availExtractionPlateId: "/plates/available/extraction/:id",
  listExtractionPlates: "/plates/extraction/list",
  deleteExtractionPlate: "/plates/extraction/delete/:id",
  updateExtractionPlateSamples: "/plates/update/to-extraction",
  extractionPlatesListSheet: "/plates/extraction/list/sheet",
  extractionPlateDetails: "/plates/extraction/:id/details",
  getExtractionPlateSheet: "/plates/extraction/:id/details/sheet",
  updateExtractionPlate: "/plates/extraction/update/:id",
  // pcr plates
  getPcrPlateOptions: "/plates/pcr/options",
  inputPcrPlateLayout: "/plates/input/pcr",
  availPcrPlateId: "/plates/available/pcr/:id",
  deletePcrPlate: "/plates/pcr/delete/:id",
  listPcrPlates: "/plates/pcr/list",
  updatePcrPlateSamples: "/plates/update/to-pcr",
  pcrPlatesListSheet: "/plates/pcr/list/sheet",
  pcrPlateDetails: "/plates/pcr/:id/details",
  getPcrPlateSheet: "/plates/pcr/:id/details/sheet",
  updatePcrPlate: "/plates/pcr/update/:id",
  // seq cells
  sequencingCellFileValidator: "/sequencing/cells/file-validation",
  getSequencingCellOptions: "/sequencing/cells/options",
  availSequencingExperimentId: "/sequencing/cells/check-id-availability",
  inputSequencingCellLayout: "/sequencing/cells/input",
  listSequencingCells: "/sequencing/cells/list",
  sequencingCellsListSheet: "/sequencing/cells/list/sheet",
  sequencingCellDetails: "/sequencing/cells/details/:id",
  getSequencingCellSheet: "/sequencing/cells/details-sheet/:id",
  updateSequencingCell: "/sequencing/cells/update/:id",
  deleteSequencingCell: "/sequencing/cells/delete/:id",
};
