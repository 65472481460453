<template>
  <div class="container mt-5">
    <b-loading :is-full-page="false" :active="loading" />

    <section v-if="institution" class="has-text-centered mb-4">
      <h2 class="subtitle is-5 has-text-grey">
        {{ institution }}
      </h2>
    </section>

    <div class="columns">
      <div class="column mt-5">
        <div class="has-text-centered subtitle is-uppercase">Totales</div>
        <div class="columns">
          <div class="column has-text-centered">
            <p class="heading">Muestras</p>
            <p class="title">
              {{ stats.total }}
            </p>
          </div>
          <div class="column has-text-centered">
            <p class="heading">Casos Positivos</p>
            <p class="title">{{ stats.positive }}</p>
          </div>
        </div>

        <template v-if="showDetailedStatistics">
          <div class="has-text-centered subtitle is-uppercase">
            {{ $date().format("dddd, D [de] MMMM") }}
          </div>
          <div class="columns">
            <div class="column has-text-centered drag-bottom">
              <p class="heading">Procesadas</p>
              <p class="title">
                <router-link
                  v-if="hasPermission('ResultsReview')"
                  :to="{ name: 'ResultsReview' }"
                >
                  {{ stats.processedToday }}
                </router-link>
                <span v-else> {{ stats.processedToday }}</span>
              </p>
            </div>
            <div class="column has-text-centered drag-bottom">
              <p class="heading">Pre-Recepcionadas</p>
              <p class="title">
                {{ stats.pending.prereceived }}
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column has-text-centered drag-bottom">
              <p class="heading">En Extracción</p>
              <p class="title">
                <router-link
                  v-if="
                    hasPermission('PendingSamples', { status: 'in-extraction' })
                  "
                  :to="{
                    name: 'PendingSamples',
                    params: { status: 'in-extraction' },
                  }"
                >
                  {{ stats.pending.inExtraction }}
                </router-link>
                <span v-else> {{ stats.pending.inExtraction }}</span>
              </p>
            </div>
            <div class="column has-text-centered drag-bottom">
              <p class="heading">En PCR</p>
              <p class="title">
                <router-link
                  v-if="hasPermission('PendingSamples', { status: 'in-pcr' })"
                  :to="{ name: 'PendingSamples', params: { status: 'in-pcr' } }"
                >
                  {{ stats.pending.inPcr }}
                </router-link>
                <span v-else> {{ stats.pending.inPcr }}</span>
              </p>
            </div>
          </div>
        </template>
      </div>
      <div class="column is-two-thirds">
        <template v-if="Object.keys(countsData).length > 0">
          <b-field position="is-right">
            <b-radio-button
              v-for="(label, option) of plotFilterOptions"
              :key="option"
              v-model="plotFilter"
              :native-value="option"
              size="is-small"
            >
              <span>{{ label }}</span>
            </b-radio-button>
          </b-field>
          <daily-cases-barplot :data="plottedData" />
          <cumulative-cases-barplot class="mt-5" :data="plottedData" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import CumulativeCasesBarplot from "@/components/plots/CumulativeCasesLineplot.vue";
  import DailyCasesBarplot from "@/components/plots/DailyCasesBarplot.vue";
  import { routes } from "@/api";
  import { hasPermission } from "@/utils";

  export default {
    name: "Home",
    components: {
      CumulativeCasesBarplot,
      DailyCasesBarplot,
    },
    data() {
      return {
        countsData: {},
        stats: {
          pending: {},
        },
        institution: "",
        loading: true,
        plotFilter: "6",
        plotFilterOptions: {
          3: "3 M",
          6: "6 M",
          12: "12 M",
          none: "Todo",
        },
      };
    },
    computed: {
      showDetailedStatistics() {
        const { roles } = this.$store.state.user;
        return roles && !roles.includes("dispatcher");
      },
      plottedData() {
        if (this.plotFilter === "none") {
          return this.countsData;
        }

        const targetDate = this.$date()
          .subtract(parseInt(this.plotFilter), "month")
          .format("YYYY-MM-DD");
        const idx = this.countsData.date.findIndex((x) => x >= targetDate);

        return Object.entries(this.countsData).reduce((o, [k, v]) => {
          return { ...o, [k]: v.slice(idx) };
        }, {});
      },
    },
    created() {
      this.axios
        .get(routes.dailyCounts)
        .then((response) => {
          this.countsData = response.data.dailyCounts;
          this.stats = response.data.stats;
          this.institution = response.data.institution;
          this.loading = false;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    methods: {
      hasPermission(routeName, routeParams) {
        return hasPermission(routeName, routeParams);
      },
    },
  };
</script>

<style scoped>
  .drag-bottom {
    margin-top: auto;
  }
</style>
