<template>
  <div class="container mt-5">
    <h1 class="title">Muestras Sin Reportar</h1>

    <p class="has-text-grey mb-4">
      Esta opción lista las muestras que ya han sido validadas pero aún no han
      sido reportadas a través de la plataforma de Toma de Muestras. Una vez
      reportados los resultados, ya no es posible cambiarlos directamente.
    </p>

    <b-table
      v-if="samples.length > 0 || loading"
      :data="samples"
      :checked-rows.sync="selectedSamples"
      :loading="loading"
      checkable
      hoverable
      narrowed
    >
      <b-table-column v-slot="props" label="Muestra" numeric centered>
        {{ props.row.sampleId }}
      </b-table-column>
      <b-table-column v-slot="props" label="N° Pet. (Ex.)" numeric centered>
        {{ props.row.institutionPrefix }}-{{ props.row.externalId }}
      </b-table-column>
      <b-table-column v-slot="props" label="Recepción" centered>
        {{ formatDateTime(props.row.receptionDatetime) }}
      </b-table-column>
      <b-table-column v-slot="props" label="Validación" centered>
        {{ formatDateTime(props.row.validationDatetime) }}
      </b-table-column>
      <b-table-column v-slot="props" label="Resultado" centered>
        <b-tag :type="tagTypes[props.row.conclusion]">
          {{ props.row.conclusion }}
        </b-tag>
      </b-table-column>

      <template slot="bottom-left">
        <b>Seleccionadas</b>: {{ selectedSamples.length }} /
        {{ samples.length }}
      </template>

      <template slot="empty"><div class="table-empty" /></template>
    </b-table>
    <div v-else-if="!loading" class="container mt-3">
      <b-message> No hay muestras sin reportar. </b-message>
    </div>

    <div v-if="samples.length > 0">
      <messages-display
        v-if="hasFailed"
        :status-messages="statusMessages"
        class="my-3"
      />

      <b-field grouped position="is-right">
        <b-button
          type="is-primary"
          :loading="submitting"
          :disabled="selectedSamples.length === 0"
          @click="submitData"
        >
          Reportar Resultados
        </b-button>
      </b-field>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime } from "@/utils";
  import MessagesDisplay from "@/components/MessagesDisplay.vue";

  export default {
    name: "UnreportedSamplesSSM",
    components: { MessagesDisplay },
    data() {
      return {
        samples: [],
        selectedSamples: [],
        statusMessages: [],
        tagTypes: {
          POSITIVO: "is-danger",
          NEGATIVO: "is-light",
          "NO CONCLUYENTE": "is-warning",
          "REPETIR MUESTRA": "is-warning",
          RECHAZADO: "is-info",
        },
        submitting: false,
        loading: false,
      };
    },
    computed: {
      hasFailed() {
        return this.statusMessages.some((x) => x.status !== "OK");
      },
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.selectedSamples = [];

        this.axios
          .get(routes.getUnreportedSamplesSSM)
          .then((response) => {
            this.samples = response.data.results;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      submitData() {
        this.submitting = true;
        this.statusMessages = [];
        this.axios
          .post(routes.sendResultsSSM, {
            validations: this.selectedSamples.map((x) => ({
              id: x.externalId,
              conclusion: x.conclusion,
              analyst_id: x.analystId,
              kit: x.kit,
            })),
          })
          .then((response) => {
            if (response.data.status === "OK") {
              this.$buefy.toast.open({
                duration: 2000,
                type: "is-success",
                message: "Resultados reportados correctamente.",
              });
            } else if (response.data.status === "warning") {
              if (response.data.results.OK.length > 0) {
                this.statusMessages.push({
                  origin: "Toma de Muestras",
                  status: "OK",
                  message: response.data.results.OK.join(", "),
                });
              }

              this.statusMessages.push({
                origin: "Toma de Muestras",
                status: "error",
                message: response.data.results.errors,
              });
            } else {
              return Promise.reject(response.data.message);
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 3000,
              message: "Ha ocurrido un error",
              type: "is-danger",
            });
            this.$log.error(e);
          })
          .finally(() => {
            this.submitting = false;
            this.loadData();
          });
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime);
      },
    },
  };
</script>

<style scoped></style>
