<template>
  <form>
    <b-loading :active="loading" :is-full-page="false" />
    <p class="has-text-grey mb-2">Filtro de datos</p>
    <div class="field is-grouped">
      <b-field label="Incidentes">
        <div class="pt-2">
          <b-radio
            v-model="incidents"
            name="name"
            :native-value="true"
            type="is-danger"
          >
            Con incidentes
          </b-radio>
          <b-radio
            v-model="incidents"
            name="name"
            :native-value="false"
            type="is-info"
          >
            Sin incidentes
          </b-radio>
          <b-radio
            v-model="incidents"
            name="name"
            :native-value="null"
            type="is-primary"
          >
            Todo
          </b-radio>
        </div>
      </b-field>
    </div>
    <div class="field is-grouped is-horizontal">
      <div class="field-body">
        <div class="columns">
          <div class="column">
            <b-field label="Desde" is-expanded>
              <b-datepicker
                v-model="dateFrom"
                placeholder="Seleccione un día"
                icon="calendar-today"
                :first-day-of-week="datepickerOptions.firstDayOfWeek"
                :day-names="datepickerOptions.dayNames"
                editable
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Hasta" is-expanded>
              <b-datepicker
                v-model="dateTo"
                placeholder="Seleccione un día"
                icon="calendar-today"
                :first-day-of-week="datepickerOptions.firstDayOfWeek"
                :day-names="datepickerOptions.dayNames"
                editable
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Operador" is-expanded>
              <b-taginput
                v-model="operator"
                class="is-narrow"
                :data="options.users"
                autocomplete
                :open-on-focus="true"
                field="option"
                icon="label"
                placeholder="Añada un operador"
                readonly
              >
              </b-taginput>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Equipo" is-expanded>
              <b-taginput
                v-model="equipment"
                :data="options.equipment"
                autocomplete
                :open-on-focus="true"
                field="option"
                icon="label"
                placeholder="Añada un equipo"
                readonly
              >
              </b-taginput>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Control" is-expanded>
              <b-taginput
                v-model="control"
                :data="options.controls"
                autocomplete
                :open-on-focus="true"
                field="option"
                icon="label"
                placeholder="Añada un control"
                readonly
              >
              </b-taginput>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Revisor" is-expanded>
              <b-taginput
                v-model="reviewer"
                :data="options.users"
                autocomplete
                :open-on-focus="true"
                field="option"
                icon="label"
                placeholder="Añada un revisor"
                readonly
              >
              </b-taginput>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <slot :filters="filters" />
      <slot :cleanInput="cleanInput" name="reset" />
    </div>
  </form>
</template>

<script>
  import { routes } from "@/api";
  import { datepickerOptions } from "@/utils";

  export default {
    name: "ExtractionPlatesFilterBar",
    components: {},
    data() {
      return {
        datepickerOptions: datepickerOptions,
        loading: false,
        dateFrom: null,
        dateTo: null,
        operator: [],
        reviewer: [],
        equipment: [],
        control: [],
        options: {},
        incidents: null,
      };
    },
    computed: {
      filters() {
        if (!this.hasFilter) return null;
        else {
          const dateFrom = this.parseDate(this.dateFrom);
          const dateTo = this.parseDate(this.dateTo);
          const operator = this.operator.map((x) => x.value);
          const reviewer = this.reviewer.map((x) => x.value);
          const equipment = this.equipment.map((x) => x.value);
          const control = this.control.map((x) => x.value);
          const incidents = this.incidents;

          return {
            incidents,
            operator,
            reviewer,
            equipment,
            control,
            dateFrom,
            dateTo,
          };
        }
      },
      hasFilter() {
        return (
          this.dateFrom !== null ||
          this.dateTo !== null ||
          this.operator.length > 0 ||
          this.reviewer.length > 0 ||
          this.control.length > 0 ||
          this.equipment.length > 0 ||
          this.incidents !== null
        );
      },
    },
    created() {
      this.getFormOptions();
    },
    methods: {
      getFormOptions() {
        this.loadingOptions = true;
        this.axios
          .get(routes.getExtractionPlateOptions)
          .then((response) => {
            this.options = response.data.results;
          })
          .catch(() => {
            this.alertError();
          })
          .finally(() => {
            this.loadingOptions = false;
          });
      },
      cleanInput() {
        this.operator = [];
        this.reviewer = [];
        this.equipment = [];
        this.control = [];
        this.dateFrom = null;
        this.dateTo = null;
        this.incidents = null;
      },
      errorAlert(message = null) {
        if (!message) {
          message = "Error de conexión con el servidor, intente denuevo";
        }
        this.$buefy.dialog.alert({
          title: "Error",
          message: message,
          type: "is-danger",
          hasIcon: true,
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      parseDate(datetime) {
        return datetime
          ? this.$date(datetime).format("YYYY-MM-DD HH:mm")
          : null;
      },
    },
  };
</script>

<style scoped></style>
