<template>
  <div class="container mt-5">
    <h1 class="title">Detalles de Lote de Muestras</h1>
    <section class="mb-4">
      <b-skeleton v-if="samplesLoading" size="is-large" />
      <span v-else class="subtitle is-5">
        Lote {{ batchId }} ({{ institution }}, {{ samples.length }}
        muestras)
      </span>
    </section>
    <section class="mb-4">
      <b-field grouped position="is-centered" group-multiline>
        <b-field>
          <b-button
            :disabled="samplesLoading"
            :loading="sheetGenerating"
            class="is-primary"
            icon-left="file-excel"
            @click="getSamplesSheet"
          >
            Descargar Planilla de Muestras
          </b-button>
        </b-field>
      </b-field>
    </section>
    <section class="mb-4">
      <div class="mb-2">
        <span class="subtitle">Eventos</span>
      </div>
      <div class="columns is-centered">
        <div class="column is-three-quarters">
          <b-table :data="batchEvents" :loading="eventsLoading">
            <b-table-column v-slot="props" label="Evento" centered>
              {{ eventTypes[props.row.eventType] }}
            </b-table-column>
            <b-table-column v-slot="props" label="Fecha/Hora" centered>
              {{ formatDateTime(props.row.datetime) }}
            </b-table-column>
            <b-table-column v-slot="props" label="Usuario" centered>
              {{ props.row.user }}
            </b-table-column>
            <template slot="empty"><div class="table-empty" /></template>
          </b-table>
        </div>
      </div>
    </section>
    <section class="mb-4">
      <div class="mb-2">
        <span class="subtitle">Muestras</span>
      </div>
      <b-field grouped group-multiline>
        <div
          v-for="(column, index) in samplesColumns"
          :key="index"
          class="control"
        >
          <b-checkbox v-model="column.visible">
            {{ column.label }}
          </b-checkbox>
        </div>
      </b-field>
      <div class="table-container">
        <b-table
          :data="samples"
          :columns="samplesColumns"
          :loading="samplesLoading"
        >
          <template slot="empty"><div class="table-empty" /></template>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import { formatDateTime } from "@/utils";

  export default {
    name: "BatchDetails",
    data() {
      return {
        batchId: this.$route.params.id,
        batchEvents: [],
        eventTypes: [],
        samples: [],
        institution: "",
        samplesColumns: [
          {
            field: "sampleId",
            label: "Muestra",
            centered: true,
            visible: true,
          },
          {
            field: "externalId",
            label: "N° Pet. (Ex)",
            centered: true,
            visible: true,
          },
          {
            field: "origin",
            label: "Procedencia",
            centered: true,
            visible: true,
          },
          {
            field: "sampleType",
            label: "Tipo de Muestra",
            centered: true,
            visible: false,
          },
          {
            field: "samplingDatetime",
            label: "Toma de Muestra",
            centered: true,
            visible: true,
          },
          {
            field: "idString",
            label: "RUN / Pas.",
            centered: true,
            visible: true,
          },
          {
            field: "names",
            label: "Nombre(s)",
            centered: true,
            visible: true,
          },
          {
            field: "firstLastname",
            label: "Apellido Paterno",
            centered: true,
            visible: true,
          },
          {
            field: "secondLastname",
            label: "Apellido Materno",
            centered: true,
            visible: true,
          },
          {
            field: "sex",
            label: "Sexo",
            centered: true,
            visible: false,
          },
          {
            field: "age",
            label: "Edad",
            centered: true,
            visible: true,
          },
          {
            field: "phone",
            label: "Teléfono",
            centered: true,
            visible: false,
          },
          {
            field: "address",
            label: "Dirección",
            centered: true,
            visible: false,
          },
          {
            field: "email",
            label: "E-mail",
            centered: true,
            visible: false,
          },
          {
            field: "conclusion",
            label: "Resultado",
            centered: true,
            visible: false,
          },
        ],
        samplesLoading: false,
        eventsLoading: false,
        documentGenerating: false,
        sheetGenerating: false,
      };
    },
    created() {
      this.samplesLoading = true;
      this.eventsLoading = true;
      // get events
      this.axios
        .get(routes.batchEvents.replace(":id", this.batchId))
        .then((response) => {
          if (response.data.status === "OK") {
            this.batchEvents = response.data.results;
            this.eventTypes = response.data.eventTypes;
            this.eventsLoading = false;
          } else {
            this.$router.push({ name: "SampleBatches" });
          }
        });

      // get samples
      this.axios
        .get(routes.batchSamples.replace(":id", this.batchId))
        .then((response) => {
          if (response.data.status === "OK") {
            this.samples = response.data.results.map((x) => ({
              ...x,
              samplingDatetime: this.$date(x.samplingDatetime).format(
                "DD-MM-YYYY HH:mm"
              ),
              conclusion: x.conclusion !== "" ? x.conclusion : "PENDIENTE",
            }));
            this.institution = response.data.institution;
            this.samplesLoading = false;
          } else {
            this.$router.push({ name: "SampleBatches" });
          }
        });
    },
    methods: {
      getSamplesSheet() {
        this.sheetGenerating = true;
        this.axios
          .get(routes.batchSamplesSheet.replace(/:id/, this.batchId), {
            responseType: "blob",
          })
          .then((response) => {
            this.sheetGenerating = false;
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `CADIUMAG_planilla_lote_${this.batchId}.xlsx`);
          });
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime);
      },
    },
  };
</script>

<style scoped></style>
