<template>
  <div class="container mt-5">
    <h1 class="title">Importación de Muestras Externas</h1>

    <p class="has-text-grey mb-2">
      Mediante esta opción se pueden importar muestras de la Plataforma Toma de
      Muestras que no hayan sido derivadas al laboratorio, y contar con sus
      datos para el proceso de secuenciación. Se creará un nuevo lote con las
      muestras secuenciadas para que se pueda realizar el seguimiento de éstas
      en el proceso de PCR.
    </p>

    <div class="mt-5">
      <p class="mb-2">
        Ingrese los códigos de la plataforma Toma de Muestras, uno por línea.
      </p>

      <b-field grouped>
        <b-input
          ref="codesInput"
          v-model="codesString"
          type="textarea"
          rows="1"
          placeholder="Códigos..."
        />
        <p class="control">
          <b-button
            icon-left="plus-thick"
            label="Importar Muestras"
            type="is-primary"
            :loading="loading"
            :disabled="loading"
            @click="getSamples"
          />
        </p>
      </b-field>

      <b-notification
        v-if="missingSamples.length > 0"
        class="mt-3"
        type="is-danger is-light"
        has-icon
        icon="alert"
      >
        <strong>Muestras no encontradas</strong>

        <div class="content">
          <ul class="mt-2">
            <li v-for="id in missingSamples" :key="id">{{ id }}</li>
          </ul>
        </div>
      </b-notification>
    </div>

    <b-notification
      v-if="registeredSamples.length > 0"
      class="mt-1"
      type="is-warning is-light"
      has-icon
      icon="alert"
    >
      <strong>Muestras que ya se encuentran ingresadas</strong>

      <div class="content">
        <ul class="mt-2">
          <li v-for="s in registeredSamples" :key="s.sampleId">
            {{ s.externalId }} (ID {{ s.sampleId }})
          </li>
        </ul>
      </div>
    </b-notification>

    <div>
      <b-field v-if="samples.length > 0" grouped position="is-right">
        <b-button
          type="is-primary"
          icon-left="delete-outline"
          :disabled="loading"
          outlined
          @click="samples = []"
        >
          Limpiar Muestras
        </b-button>
      </b-field>
      <b-table
        v-if="Object.keys(samplesSimplified).length > 0"
        :data="samplesSimplified"
      >
        <b-table-column
          v-for="column in samplesTableColumns"
          :key="column.field"
          v-slot="props"
          :label="column.label"
          :field="column.field"
          :centered="column.centered"
          cell-class="valign-mid"
        >
          {{ props.row[column.field] }}
        </b-table-column>
        <b-table-column v-slot="props">
          <b-button type="is-text" @click="deleteSample(props.index)">
            <b-icon icon="delete" />
          </b-button>
        </b-table-column>
      </b-table>
    </div>

    <b-field v-if="samples.length > 0" grouped position="is-right" class="mt-2">
      <b-button
        type="is-primary"
        icon-left="content-save"
        :loading="saving"
        :disabled="saving"
        @click="saveSamples"
      >
        Guardar Muestras como Lote
      </b-button>
    </b-field>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime } from "@/utils";

  export default {
    name: "ImportExtSamples",
    data() {
      return {
        samples: [],
        registeredSamples: [],
        missingSamples: [],
        codesString: "",
        loading: false,
        saving: false,
        samplesTableColumns: [
          { field: "externalId", label: "ID SSM", centered: true },
          { field: "idString", label: "RUN / Pas.", centered: true },
          { field: "fullName", label: "Nombre", centered: true },
          { field: "institution", label: "Establecimiento", centered: true },
          {
            field: "samplingDatetime",
            label: "Toma de Muestra",
            centered: true,
          },
        ],
      };
    },
    computed: {
      sampleIds() {
        const ids = this.codesString
          .split("\n")
          .map((x) => parseInt(x.trim()))
          .filter((x) => !isNaN(x));
        return [...new Set(ids)];
      },
      samplesSimplified() {
        return this.samples.map((x) => ({
          externalId: x.externalId,
          idString: x.idString,
          institution: x.institution,
          fullName: `${x.firstLastname} ${x.secondLastname} ${x.names}`,
          samplingDatetime: formatDateTime(x.samplingDatetime, true),
        }));
      },
    },
    watch: {
      codesString() {
        this.resizeTextArea();
      },
    },
    methods: {
      async getSamples() {
        this.loading = true;
        this.registeredSamples = [];
        this.missingSamples = [];
        const response = await this.axios.post(routes.importSamples, {
          sample_ids: this.sampleIds,
        });

        this.samples = this.samples.concat(response.data.samples);
        this.registeredSamples = response.data.registered;
        this.missingSamples = response.data.missing;

        this.codesString = "";
        this.resizeTextArea();
        this.loading = false;
      },
      async saveSamples() {
        this.saving = true;

        try {
          const response = await this.axios.post(routes.preReceiveSamplesSSM, {
            samples: this.samples,
            institution_id: parseInt(
              String(process.env.VITE_SEQUENCING_INSTITUTION_ID)
            ),
            from_integration: false,
          });

          if (response.data.status !== "OK") {
            // noinspection ExceptionCaughtLocallyJS
            throw response.data.message;
          }

          this.samples = [];
          this.registeredSamples = [];
          this.missingSamples = [];
          this.$buefy.toast.open({
            message: `Lote ${response.data.batchId} creado correctamente con las muestras seleccionadas.`,
            type: "is-success",
            duration: 3000,
          });
        } catch (e) {
          this.$buefy.toast.open({
            message: `Ha ocurrido un error al intentar ingresar las muestras.`,
            type: "is-danger",
            duration: 3000,
          });
        }

        this.saving = false;
      },
      deleteSample(idx) {
        this.samples.splice(idx, 1);
      },
      resizeTextArea() {
        const element = this.$refs.codesInput.$refs.textarea;
        element.style.height = "0px";
        element.style.height = `${Math.min(element.scrollHeight + 2, 150)}px`;
      },
    },
  };
</script>

<style scoped></style>
