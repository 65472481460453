<template>
  <div>
    <h2 class="subtitle">{{ institution.name }} {{ laboratoryString }}</h2>
    <div class="level">
      <div class="level-left">
        <b-button
          icon-left="arrow-left"
          type="is-light"
          :disabled="saving"
          @click="$emit('unselect')"
        >
          Volver
        </b-button>
      </div>
      <div class="level-right">
        <b-field grouped>
          <b-field>
            <b-button
              icon-left="download"
              type="is-primary"
              :disabled="selectedSamples.length === 0"
              :loading="saving"
              @click="preReceiveSamples"
            >
              Pre-recepcionar seleccionadas ({{ selectedSamples.length }})
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-table
      :data="samplesFormatted"
      :checked-rows.sync="selectedSamples"
      narrowed
      hoverable
      checkable
    >
      <template v-for="column in tableColumns">
        <b-table-column
          v-slot="props"
          :key="column.field"
          v-bind="column"
          :sortable="column.sortable"
          cell-class="valign-mid"
        >
          <template v-if="column.field.includes('Datetime')">
            {{ formatDateTime(props.row[column.field]) }}
          </template>
          <template v-else>
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>
      <b-table-column
        v-slot="props"
        label="Prioridad"
        cell-class="valign-mid"
        centered
        sortable
      >
        <span :class="props.row.priority.class">{{
          props.row.priority.label
        }}</span>
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
  import { formatDateTime } from "@/utils";
  import { routes } from "@/api";

  export default {
    name: "SampleReceptionSSM",
    props: {
      samples: {
        type: Array,
        required: true,
      },
      institution: {
        type: Object,
        required: true,
      },
      laboratory: {
        type: String,
        default: "-",
      },
    },
    data() {
      return {
        selectedSamples: [],
        tableColumns: [
          {
            field: "id",
            label: "Número de Muestra",
            centered: true,
            sortable: true,
          },
          {
            field: "idString",
            label: "RUN / Pasaporte",
            centered: true,
            sortable: true,
          },
          { field: "name", label: "Nombre", centered: true, sortable: true },
          {
            field: "samplingDatetime",
            label: "Toma de Muestra",
            centered: true,
            sortable: true,
          },
          {
            field: "dispatchDatetime",
            label: "Despacho",
            centered: true,
            sortable: true,
          },
        ],
        priorityOptions: {
          normal: { label: "Normal", class: "" },
          high: { label: "Media", class: "has-text-warning bold" },
          urgent: { label: "Alta", class: "has-text-danger bold" },
        },
        saving: false,
      };
    },
    computed: {
      samplesFormatted() {
        return this.samples.map((x) => ({
          id: x.externalId,
          idString: x.idString,
          name: `${x.firstLastname} ${
            x.secondLastname ? x.secondLastname : ""
          } ${x.names}`,
          dispatchDatetime: this.$date(x.dispatchDatetime),
          samplingDatetime: this.$date(x.samplingDatetime),
          priority: this.priorityOptions[x.priority],
        }));
      },
      laboratoryString() {
        return this.laboratory !== "-"
          ? `(derivado por ${this.laboratory})`
          : "";
      },
    },
    watch: {
      samplesFormatted(val) {
        if (val.length === 0) {
          this.$emit("unselect");
        }
      },
    },
    methods: {
      preReceiveSamples() {
        const selectedSampleIds = this.selectedSamples.map((x) => x.id);
        const selectedSamplesFull = this.samples.filter((x) =>
          selectedSampleIds.includes(x.externalId)
        );

        this.saving = true;
        this.axios
          .post(routes.preReceiveSamplesSSM, {
            samples: selectedSamplesFull,
            institution_id: this.institution.id,
          })
          .then((response) => {
            if (response.data.status === "OK") {
              this.$buefy.toast.open({
                message: `Lote ${response.data.batchId} creado correctamente con las muestras seleccionadas.`,
                type: "is-success",
                duration: 3000,
              });
              this.$emit("preceived-samples", selectedSampleIds);
            } else {
              return Promise.reject(response.data.message);
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: `Ha ocurrido un error al intentar ingresar las muestras.`,
              type: "is-danger",
              duration: 3000,
            });
            this.$log.error(e);
          })
          .finally(() => {
            this.saving = false;
          });
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime);
      },
    },
  };
</script>

<style scoped>
  .bold {
    font-weight: bold;
  }
</style>
