<template>
  <div class="container mt-5">
    <h1 class="title">Entrenamiento de qPCR</h1>
    <p class="has-text-grey">
      Seleccione un día para acceder a los experimentos.
    </p>

    <b-loading :active="experimentsLoading" :is-full-page="false" />
    <div class="columns mt-4">
      <div class="column is-two-fifths">
        <b-datepicker
          v-model="selectedDate"
          :first-day-of-week="1"
          :day-names="datepickerOptions.dayNames"
          :month-names="datepickerOptions.monthNames"
          :min-date="experimentDates[0]"
          :max-date="experimentDates[experimentDates.length - 1]"
          :selectable-dates="experimentDates"
          :events="calendarEvents"
          indicators="bars"
          inline
          class="has-text-centered"
        >
          <template slot="default">
            <p class="has-text-left">
              <b-icon icon="square" type="is-info" size="is-small" />
              <span class="has-text-grey">
                Día con experimentos completamente validados
              </span>
            </p>
            <p class="has-text-left">
              <b-icon icon="square" type="is-warning" size="is-small" />
              <span class="has-text-grey">
                Día con experimentos parcialmente validados
              </span>
            </p>
            <p class="has-text-left">
              <b-icon icon="square" type="is-light" size="is-small" />
              <span class="has-text-grey">
                Día con experimentos sin validar
              </span>
            </p>
          </template>
        </b-datepicker>
      </div>
      <div class="column is-three-fifths">
        <div
          v-for="(experiment, i) in experimentsOnSelectedDate"
          :key="i"
          class="box"
        >
          <div class="level">
            <div class="level-left">
              <div>
                <p><strong>Nombre:</strong> {{ experiment.filename }}</p>
                <p><strong>Equipo:</strong> {{ experiment.equipment }}</p>
                <p><strong>Kit:</strong> {{ experiment.kit }}</p>
                <p><strong>Muestras:</strong> {{ experiment.nSamples }}</p>
                <p v-if="experiment.userValidated > 0" class="mt-2">
                  <b-button
                    type="is-light"
                    icon-left="download"
                    @click="downloadValidations(experiment.id)"
                  >
                    Descargar datos de validación
                  </b-button>
                </p>
              </div>
            </div>
            <div class="level-right level-top">
              <div class="has-text-right">
                <p class="mt-2">
                  <b-taglist>
                    <b-tag size="is-medium">
                      <b-icon icon="format-list-checks" size="is-small" />
                      <span>{{ experiment.completedPerc }} %</span>
                    </b-tag>
                    <b-tag size="is-medium">
                      <b-icon icon="account-multiple" size="is-small" />
                      <span>{{ experiment.userValidated }}</span>
                    </b-tag>
                  </b-taglist>
                </p>
                <p class="mt-2">
                  <b-button
                    v-if="experiment.completedPerc < 100"
                    type="is-primary"
                    icon-right="check-outline"
                    class="is-right"
                    tag="router-link"
                    :to="{
                      name: 'QpcrTrainingExperiment',
                      params: { id: experiment.id },
                    }"
                  >
                    Validar
                  </b-button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="has-text-grey">
            <b-icon icon="format-list-checks" size="is-small" /><span>
              Porcentaje de muestras completado
            </span>
          </p>
          <p class="has-text-grey">
            <b-icon icon="account-multiple" size="is-small" /><span>
              Cantidad de usuarios que validaron el experimento (incluyéndome)
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { saveAs } from "file-saver";
  import { routes } from "@/api";
  import { datepickerOptions } from "@/utils";

  export default {
    name: "QpcrTrainingList",
    data() {
      return {
        selectedDate: new Date(),
        datepickerOptions,
        experiments: [],
        experimentsLoading: false,
      };
    },
    computed: {
      experimentDates() {
        return this.experiments
          .map((x) => x.date)
          .filter(
            (date, i, self) =>
              self.findIndex((d) => d.getTime() === date.getTime()) === i
          )
          .sort((a, b) => a.getTime() - b.getTime());
      },
      calendarEvents() {
        const dateStatuses = this.experiments.reduce((o, v) => {
          const ts = v.date.getTime();
          if (!o[ts]) {
            o[ts] = new Set();
          }

          if (v.completedPerc === 0) {
            o[ts].add("is-light");
          } else if (v.completedPerc === 100) {
            o[ts].add("is-info");
          } else {
            o[ts].add("is-warning");
          }

          return o;
        }, {});

        return Object.entries(dateStatuses).reduce((a, [k, v]) => {
          const dateEvents = [...v].map((x) => ({
            date: new Date(parseInt(k)),
            type: x,
          }));
          return [...a, ...dateEvents];
        }, []);
      },
      experimentsOnSelectedDate() {
        return this.experiments.filter(
          (x) => x.date.getTime() === this.selectedDate.getTime()
        );
      },
    },
    watch: {
      experimentDates(val) {
        if (!this.$route.query.date) {
          this.selectedDate = val[Math.trunc(val.length / 2)];
        }
      },
    },
    created() {
      this.loadExperiments();

      if (this.$route.query.date) {
        this.selectedDate = this.$date(this.$route.query.date).toDate();
      }
    },
    methods: {
      loadExperiments() {
        this.experimentsLoading = true;
        this.axios
          .get(routes.listPCRFiles)
          .then((response) => {
            this.experiments = response.data.files.map((x) => ({
              ...x,
              date: this.$date(x.date).toDate(),
              completedPerc: parseInt(
                ((1 - x.nPendingSamples / x.nSamples) * 100).toFixed(0)
              ),
            }));
          })
          .finally(() => {
            this.experimentsLoading = false;
          });
        return [];
      },
      downloadValidations(experiment_id) {
        this.axios
          .get(
            routes.downloadExperimentValidations.replace(/:id/, experiment_id)
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "text/csv",
            });
            saveAs(blob, `validaciones_exp${experiment_id}.csv`);
          });
      },
    },
  };
</script>

<style scoped>
  .level-top {
    margin-bottom: auto;
  }
</style>
