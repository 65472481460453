<template>
  <div class="container mt-5">
    <b-loading :active="loading" :is-full-page="false" />
    <h1 class="title">Validación de Experimento</h1>
    <h2 v-if="Object.keys(experimentInfo).length > 0" class="subtitle">
      {{ experimentInfo.filename }} ({{ experimentInfo.date }})
    </h2>

    <div class="mt-5">
      <div class="column has-text-right">
        <div class="field">
          <b-button type="is-primary" icon-left="check-bold" @click="preSubmit">
            Guardar Validación
          </b-button>
        </div>
      </div>
    </div>

    <qpcr-analysis
      :raw-values="rawValues"
      :grid="grid"
      :metadata="metadata"
      @update-cutoff="updateCutoff"
    />
  </div>
</template>

<script>
  import QpcrAnalysis from "@/components/qpcr-analysis/QpcrAnalysis.vue";
  import { routes } from "@/api";
  import { datepickerOptions } from "@/utils";

  export default {
    name: "QpcrTrainingExperiment",
    components: {
      QpcrAnalysis,
    },
    data() {
      return {
        grid: {},
        experimentInfo: {},
        layoutId: null,
        rawValues: {},
        metadata: [],
        cutoffs: {},
        loading: false,
        datepickerOptions,
      };
    },
    created() {
      this.loadExperimentData();
      this.loadExperimentInfo();
    },
    methods: {
      loadExperimentInfo() {
        this.axios
          .get(routes.getExperimentInfo.replace(/:id/, this.$route.params.id))
          .then((response) => {
            this.experimentInfo = response.data.result;
          });
      },
      loadExperimentData() {
        this.loading = true;
        this.axios
          .get(routes.getExperimentData.replace(/:id/, this.$route.params.id))
          .then((response) => {
            this.rawValues = response.data.rawData;
            this.metadata = response.data.metadata.map((x) => ({
              ...x,
              cq: null,
              cqMean: null,
            }));
            this.grid = response.data.grid;
            this.layoutId = response.data.layoutId;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      preSubmit() {
        const pendingTargets = [
          ...new Set(this.metadata.map((x) => x.targetName)),
        ].filter((x) => !Object.keys(this.cutoffs).includes(x));

        if (pendingTargets.length > 0) {
          this.$buefy.dialog.confirm({
            message:
              "¿Está seguro que desea guardar la validación? Aún no se han establecido puntos de corte para: " +
              `${pendingTargets.join(", ")}`,
            onConfirm: () => this.submit(),
            confirmText: "Sí, guardar",
            cancelText: "Cancelar",
          });
        } else {
          this.submit();
        }
      },
      submit() {
        const ctData = this.metadata.reduce((o, v) => {
          if (!o[v.reporter]) {
            o[v.reporter] = {};
          }
          o[v.reporter][parseInt(v.id)] = parseFloat(v.cq);
          return o;
        }, {});

        this.axios
          .post(
            routes.saveExperimentValidation.replace(
              /:id/,
              this.$route.params.id
            ),
            {
              ct: ctData,
              cutoffs: this.cutoffs,
              layout: this.layoutId,
            }
          )
          .then(() => {
            this.$buefy.toast.open({
              duration: 2000,
              message: "Cambios guardados correctamente",
              type: "is-success",
            });
            this.$router.push({
              name: "QpcrTrainingList",
              query: { date: this.experimentInfo.date },
            });
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 3000,
              message: "Ha ocurrido un error",
              type: "is-danger",
            });
            this.$log.error(e);
          });
      },
      updateCutoff(target, value) {
        this.cutoffs[target] = value;
      },
    },
  };
</script>

<style scoped></style>
