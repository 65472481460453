<template>
  <div>
    <chartjs-line-plot :chart-data="datasets" :options="options" />
    <div class="has-text-centered">
      <span
        v-for="(channel, index) in targets"
        :key="index"
        class="has-text-centered"
      >
        <b-checkbox
          v-for="gene in channel"
          :key="gene"
          v-model="channelsSelected"
          :native-value="gene"
          class="ml-2"
        >
          {{ gene }}
        </b-checkbox>
      </span>
    </div>
  </div>
</template>

<script>
  /* WIP */
  /* eslint-disable */
  import ChartjsLinePlot from "@/components/plots/ChartjsLinePlot.vue";
  import { qPcrLineChartDefaultOptions } from "@/components/plots/_defaults";

  export default {
    name: "QpcrLinePlot",
    components: {
      ChartjsLinePlot,
    },
    props: {
      rawValues: {
        type: Object,
        required: true,
      },
      targets: {
        type: Object,
        required: true,
      },
      targetNames: {
        type: Object,
        required: true,
      },
      targetValue: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        channelsSelected: [],
        channelColors: {
          FAM: "rgba(218, 42, 42, 0.6)",
          VIC: "rgba(50, 115, 220, 0.6)",
        },
        cutoffValues: {},
        defaultOptions: qPcrLineChartDefaultOptions,
      };
    },
    computed: {
      datasets() {
        const datasets = Object.entries(this.$props.rawValues).reduce(
          (a, [k, v]) => {
            for (let channel of Object.keys(v)) {
              if (
                this.channelsSelected.includes(this.targetNames[k][channel])
              ) {
                a.push({
                  borderColor: this.channelColors[channel],
                  data: v[channel],
                  label: `Muestra ${k}`,
                  fill: false,
                  borderWidth: 1.5,
                });
              }
            }
            return a;
          },
          []
        );
        const labels =
          datasets.length > 0
            ? Array.from({ length: datasets[0].data.length }, (x, i) => i + 1)
            : [];
        return { datasets, labels };
      },
      // annotations() {
      //   // const annotationTemplate = {
      //   //   type: "line",
      //   //   mode: "horizontal",
      //   //   borderColor: "#000",
      //   //   borderWidth: 2,
      //   // };
      //
      //   let annotations = {};
      //   // one annotation by target
      //   for (let channel of Object.keys(this.targets)) {
      //     for (let target of this.targets[channel]) {
      //       if (this.channelsSelected.includes(target)) {
      //         annotations[target] = {
      //           type: "line",
      //           mode: "horizontal",
      //           // scaleID: "y-axis-0",
      //           value: this.cutoffValues[target],
      //           borderColor: "#000",
      //           borderWidth: 2,
      //           draggable: true,
      //           // display: true,
      //           label: {
      //             enabled: true,
      //             content: `${target}: ${this.cutoffValues[target].toFixed(0)}`,
      //             position: "left",
      //           },
      //           // onDragEnd: this.updatedAnnotation,
      //         };
      //       }
      //     }
      //   }
      //
      //   return annotations;
      // },
      options() {
        return {
          ...this.defaultOptions,
          plugins: {
            autocolors: false,
            annotation: {
              annotations: {
                line1: {
                  type: "line",
                  yMin: 60,
                  yMax: 60,
                  borderColor: "rgb(255, 99, 132)",
                  borderWidth: 2,
                },
              },
            },
            ...this.defaultOptions.plugins,
          },
        };
      },
    },
    // watch: {
    //   targetNames() {
    //     // when is a new file, update plot (delete annotation of old dataset)
    //     this.channelsSelected = [];
    //     this.resetCutoffs();
    //   },
    // },
    created() {
      this.resetCutoffs();
    },
    methods: {
      resetCutoffs() {
        const targets = Object.values(this.targets).reduce((a, v) => {
          return [...a, ...v];
        }, []);
        const values = Array.from(
          { length: targets.length },
          (x, i) => (1 + (i - 1) * 0.2) * this.targetValue
        );

        this.cutoffValues = targets.reduce(
          (o, k, i) => ({ ...o, [k]: values[i] }),
          {}
        );
      },
      // updatedAnnotation(e) {
      //   let annot = e.subject.config.id; //target
      //   let reporter = Object.keys(this.targets).find((x) =>
      //     this.targets[x].includes(annot)
      //   );
      //
      //   this.cutoffValues[annot] =
      //     e.subject.chart.annotation.elements[annot].options.value;
      //   this.$emit("update-cutoff", annot, reporter, this.cutoffValues[annot]);
      //   e.subject.chart.annotation.elements[annot].options.label.content =
      //     annot + ": " + this.cutoffValues[annot].toFixed(0);
      //   e.subject.chart.update();
      // },
    },
  };
</script>

<style>
  .pre {
    white-space: pre;
  }
</style>
