<template>
  <div class="container mt-5">
    <h1 class="title">Impresión de Etiquetas</h1>

    <p class="has-text-grey">
      Esta opción permite la impresión de etiquetas, seleccionando un conjunto
      de lotes de muestras ya recepcionados y aún no validados. El número de
      muestras indicado corresponde a las que no han sido rechazadas o
      validadas.
    </p>
    <b-field grouped position="is-centered" class="mt-4">
      <b-field label="Desde">
        <b-datetimepicker
          v-model="datetimeFrom"
          icon="calendar-today"
          :datepicker="datepickerOptions"
          :timepicker="{ hourFormat: '24' }"
          :disabled="loading"
        />
      </b-field>
      <b-field label="Hasta">
        <b-datetimepicker
          v-model="datetimeTo"
          icon="calendar-today"
          :datepicker="datepickerOptions"
          :disabled="loading"
        />
      </b-field>
      <b-field>
        <template slot="label"><br /></template>
        <b-button type="is-primary" :disabled="loading" @click="loadBatches">
          Filtrar
        </b-button>
      </b-field>
    </b-field>

    <b-field grouped position="is-right">
      <div class="level">
        <div class="label mr-3">Etiquetas: {{ nLabels }}</div>
        <b-button
          icon-left="printer"
          type="is-primary"
          :disabled="selectedBatches.length === 0"
          :loading="generating"
          @click="generateLabels"
        >
          Generar etiquetas
        </b-button>
      </div>
    </b-field>

    <b-table
      v-if="batches.length > 0 || loading"
      :data="batches"
      checkable
      :loading="loading"
      :checked-rows.sync="selectedBatches"
    >
      <b-table-column v-slot="props" field="id" label="N°" centered>
        {{ props.row.id }}
      </b-table-column>
      <b-table-column v-slot="props" field="institution" label="Institución">
        {{ props.row.institution }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="samples"
        label="Muestras"
        centered
        numeric
      >
        {{ props.row.samples }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="receptionDatetime"
        label="Recepción"
        centered
      >
        {{ formatDatetime(props.row.receptionDatetime) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="labelsPrinted"
        label="Etiquetas impresas"
        centered
      >
        {{ props.row.labelsPrinted ? "Sí" : "No" }}
      </b-table-column>
      <template slot="empty"><div class="table-empty" /></template>
    </b-table>
    <div v-else class="container mt-5">
      <b-message>
        No hay lotes con muestras pendientes en el rango indicado.
      </b-message>
    </div>

    <label-legend class="mt-3" :institutions="institutions" />
  </div>
</template>

<script>
  import { saveAs } from "file-saver";
  import { routes } from "@/api";
  import LabelLegend from "@/components/samples/LabelLegend.vue";
  import { datepickerOptions, formatDateTime } from "@/utils";

  export default {
    name: "LabelPrinting",
    components: { LabelLegend },
    data() {
      return {
        batches: [],
        selectedBatches: [],
        institutions: [],
        datetimeFrom: null,
        datetimeTo: null,
        datepickerOptions,
        loading: false,
        generating: false,
      };
    },
    computed: {
      nLabels() {
        return this.selectedBatches
          .map((x) => x.samples)
          .reduce((s, v) => s + v, 0);
      },
    },
    created() {
      this.setDatetimes();
      this.loadInstitutions();
      this.loadBatches();
    },
    methods: {
      loadInstitutions() {
        this.axios.get(routes.getInstitutions).then((reponse) => {
          this.institutions = reponse.data.results;
        });
      },
      loadBatches() {
        this.loading = true;
        this.selectedBatches = [];
        this.axios
          .get(routes.listBatchesForLabels, {
            params: {
              datetime_from: this.datetimeToStr(this.datetimeFrom),
              datetime_to: this.datetimeToStr(this.datetimeTo),
            },
          })
          .then((response) => {
            this.batches = response.data.results;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      generateLabels() {
        const batchList = this.selectedBatches.map((x) => x.id);

        this.generating = true;
        this.axios
          .post(
            routes.getLabels,
            { batch_ids: batchList },
            { responseType: "blob" }
          )
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            saveAs(blob, `labels_${this.$date().format("YYYYMMDD_HHmm")}.pdf`);
          })
          .finally(() => {
            this.generating = false;
            this.loadBatches();
          });
      },
      datetimeToStr(datetime) {
        return this.$date(datetime).format("YYYY-MM-DD HH:mm");
      },
      formatDatetime(datetime) {
        return formatDateTime(datetime);
      },
      setDatetimes() {
        const now = this.$date();
        if (now.hour() < 15) {
          this.datetimeFrom = now.hour(9).minute(0).toDate();
          this.datetimeTo = now.hour(15).minute(0).second(0).toDate();
        } else {
          this.datetimeFrom = now.hour(15).minute(0).second(1).toDate();
          this.datetimeTo = now.hour(20).minute(0).toDate();
        }
      },
    },
  };
</script>

<style scoped></style>
