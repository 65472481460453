<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Rechazo de Muestra</p>
      </header>
      <section class="modal-card-body">
        <p class="mb-4 has-text-grey">
          Rechazar una muestra excluirá la muestra de procesos posteriores,
          generará un reporte de rechazo, e informará la muestra como rechazada
          en la plataforma de Toma de Muestras si es que esta muestra proviene
          desde ese origen. Esta operación es
          <strong>IRREVERSIBLE</strong>.
        </p>
        <p class="mb-3">
          <strong>
            Muestra a Rechazar: {{ sample.sampleId }} (Ext.
            {{ sample.externalId }})
          </strong>
        </p>
        <b-field label="Motivo">
          <b-select v-model="selectedOption" required>
            <option
              v-for="rejectOption in rejectOptionsList"
              :key="rejectOption.id"
              :value="rejectOption.id"
            >
              {{ rejectOption.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Comentarios">
          <b-input v-model="observations" :maxlength="128" type="textarea" />
        </b-field>

        <messages-display v-if="hasFailed" :status-messages="statusMessages" />
      </section>
      <footer class="modal-card-foot">
        <b-button :disabled="saving" @click="$parent.close()">
          Cancelar
        </b-button>
        <b-button
          type="is-danger"
          :loading="saving"
          :disabled="selectedOption === null"
          @click="rejectSample"
        >
          Rechazar Muestra
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
  import { routes } from "@/api";
  import MessagesDisplay from "@/components/MessagesDisplay.vue";

  export default {
    name: "RejectSampleModal",
    components: { MessagesDisplay },
    props: {
      sample: {
        type: Object,
        required: true,
      },
      rejectionOptions: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        observations: "",
        selectedOption: null,
        statusMessages: [],
        saving: false,
      };
    },
    computed: {
      rejectOptionsList() {
        return Object.entries(this.rejectionOptions).map(([k, v]) => ({
          id: k,
          name: v,
        }));
      },
      hasFailed() {
        return this.statusMessages.some((x) => x.status !== "OK");
      },
    },
    methods: {
      rejectSample() {
        this.saving = true;
        this.statusMessages = [];
        (this.sample.fromIntegration
          ? this.rejectSampleSSM()
          : this.rejectSampleInternal()
        )
          .then((response) => {
            this.statusMessages.push({
              origin: this.sample.fromIntegration
                ? "Toma de Muestras"
                : "Registro Interno",
              status: response.data.status,
              message: response.data.message,
            });
            if (response.data.status !== "OK") {
              return Promise.reject(response.data.message);
            } else {
              return Promise.resolve();
            }
          })
          .then(() => {
            this.$buefy.toast.open({
              message: `Rechazo realizado correctamente.`,
              type: "is-success",
              duration: 3000,
            });
            this.$emit("saved", this.$parent);
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.saving = false;
          });
      },
      rejectSampleSSM() {
        return this.axios.post(routes.rejectSampleSSM, {
          externalId: this.sample.externalId,
          sampleId: this.sample.sampleId,
          rejectionOptionId: Number(this.selectedOption),
          observations: this.observations,
          internalObservations: `${
            this.rejectionOptions[this.selectedOption]
          }. ${this.observations}`,
        });
      },
      rejectSampleInternal() {
        return this.axios.post(routes.rejectSample, {
          sampleId: this.sample.sampleId,
          observations: `${this.rejectionOptions[this.selectedOption]}. ${
            this.observations
          }`,
        });
      },
    },
  };
</script>

<style scoped></style>
