<template>
  <div class="container mt-5">
    <h1 class="title">Conservación / Destrucción de Muestras</h1>

    <p class="has-text-grey">
      Esta pantalla permite guardar información respecto a la conservación o
      destrucción de muestras. <br />
      Es posible la selección de un grupo de muestras y la especificación de
      fecha y hora. Se pueden agregar anotaciones al proceso (condiciones de
      almacenamiento, si la muestra se terminó, etc).
    </p>

    <b-field grouped position="is-right">
      <b-field label="Búsqueda">
        <b-switch v-model="searchable" size="is-medium" :rounded="false">
        </b-switch>
      </b-field>
      <b-field label="Filas por página">
        <b-numberinput
          v-model="samplesPerPage"
          controls-position="compact"
          step="10"
          class="number-input-150"
        />
      </b-field>
    </b-field>

    <b-table
      :data="samples"
      hoverable
      checkable
      :loading="loading"
      :checked-rows.sync="selectedSamples"
      paginated
      :per-page="samplesPerPage"
    >
      <b-table-column
        v-slot="props"
        field="sampleId"
        label="Muestra"
        centered
        sortable
        numeric
        :searchable="searchable"
      >
        <template v-if="props.row.externalId">
          {{ props.row.institution }}-{{ props.row.externalId }}
        </template>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="receptionDatetime"
        label="Recepción"
        centered
        sortable
      >
        {{ formatDateTime(props.row.receptionDatetime) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="validationDatetime"
        label="Validación"
        centered
        sortable
      >
        {{ formatDateTime(props.row.validationDatetime) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="conclusion"
        label="Resultado"
        centered
        sortable
        :searchable="searchable"
      >
        <b-tag :type="tagTypes[props.row.conclusion]" size="is-medium">
          {{ props.row.conclusion }}
        </b-tag>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="closureType"
        label="Opción"
        centered
      >
        <b-field position="is-centered">
          <b-radio-button
            v-model="props.row.closureType"
            native-value="destruction"
            type="is-danger"
          >
            <b-tooltip label="Eliminada" type="is-light">
              <b-icon icon="delete"></b-icon>
            </b-tooltip>
          </b-radio-button>

          <b-radio-button
            v-model="props.row.closureType"
            native-value="storage"
            type="is-success"
          >
            <b-tooltip label="Guardada" type="is-light">
              <b-icon icon="fridge"></b-icon>
            </b-tooltip>
          </b-radio-button>
        </b-field>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="annotations"
        label="Anotaciones"
        centered
      >
        <b-button class="is-text" expanded @click="editAnnotation(props.row)">
          <b-icon v-if="props.row.annotations === null" icon="plus" />
          <b-icon v-else icon="pencil" />
        </b-button>
      </b-table-column>

      <template slot="bottom-left">
        <b>Seleccionadas</b>: {{ selectedSamples.length }} /
        {{ samples.length }}
      </template>

      <template slot="empty"><div class="table-empty" /></template>
    </b-table>

    <div v-if="samples.length > 0" class="mt-5">
      <b-field grouped position="is-right">
        <div class="field-label is-normal">
          <label class="label"> Fecha / Hora </label>
        </div>

        <b-datetimepicker
          v-model="datetime"
          name="datetime"
          placeholder="Seleccione una fecha..."
          :datepicker="datepickerOptions"
          :timepicker="{ hourFormat: '24' }"
          position="is-top-left"
          trap-focus
        />

        <b-button
          type="is-primary"
          :loading="submitting"
          :disabled="selectedSamples.length === 0"
          @click="submitData"
        >
          Enviar
        </b-button>
      </b-field>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import AnnotationModal from "@/components/pcr-validation/AnnotationModal.vue";
  import { datepickerOptions, formatDateTime } from "@/utils";

  export default {
    name: "SampleClosure",
    components: {
      // eslint-disable-next-line vue/no-unused-components
      AnnotationModal,
    },
    data() {
      return {
        samples: [],
        selectedSamples: [],
        closureOptions: {},
        datetime: new Date(),
        tagTypes: {
          POSITIVO: "is-danger",
          NEGATIVO: "is-light",
          "NO CONCLUYENTE": "is-info",
        },
        searchable: false,
        samplesPerPage: 30,
        submitting: false,
        loading: false,
        datepickerOptions,
      };
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.axios
          .get(routes.samplesForClosure)
          .then((response) => {
            this.samples = response.data.results.map((x) => {
              return {
                ...x,
                closureType:
                  x.conclusion === "POSITIVO" ? "storage" : "destruction",
                annotations: null,
              };
            });
            this.closureOptions = response.data.closureOptions;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      editAnnotation(row) {
        this.$buefy.modal.open({
          parent: this,
          component: AnnotationModal,
          props: {
            title: "Anotaciones",
            text: row.annotations,
            textLength: 255,
            withDelete: row.annotations !== null,
          },
          events: {
            save: (annotation, modal) => {
              row.annotations = annotation;
              modal.close();
            },
            delete: (modal) => {
              row.annotations = null;
              modal.close();
            },
          },
          hasModalCard: true,
          trapFocus: true,
        });
      },
      submitData() {
        const filteredData = this.selectedSamples.map(
          ({ sampleId, closureType, annotations }) => {
            return { sampleId, closureType, annotations };
          }
        );

        this.submitting = true;
        this.axios
          .post(routes.submitClosure, {
            closureData: filteredData,
            datetime: this.$date(this.datetime).format("YYYY-MM-DD HH:mm"),
          })
          .then(() => {
            this.$buefy.toast.open({
              duration: 2000,
              message: "Cambios guardados correctamente",
              type: "is-success",
            });
            this.loadData();
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 3000,
              message: "Ha ocurrido un error",
              type: "is-danger",
            });
            this.$log.error(e);
          })
          .finally(() => {
            this.submitting = false;
          });
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime);
      },
    },
  };
</script>

<style scoped>
  .number-input-150 {
    max-width: 150px;
  }
</style>
