<template>
  <div class="container mt-5">
    <h1 class="title">Lotes de Muestras</h1>

    <div class="has-text-right">
      <b-switch
        v-model="pendingOnly"
        :disabled="loading"
        :rounded="false"
        @input="loadData"
      >
        Sólo lotes con muestras pendientes
      </b-switch>
    </div>

    <b-table
      :data="batchesWithAction"
      :loading="loading"
      hoverable
      :paginated="!pendingOnly"
      :per-page="batchesPerPage"
    >
      <b-table-column
        v-slot="props"
        field="id"
        label="N°"
        cell-class="valign-mid"
        centered
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="institution"
        label="Institución"
        cell-class="valign-mid"
      >
        {{ props.row.institution }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="samples"
        label="Muestras"
        cell-class="valign-mid"
        centered
        numeric
      >
        <b-tooltip
          v-if="props.row.counts"
          :label="formatCounts(props.row.counts)"
          type="is-light"
          dashed
        >
          <span>{{ props.row.samples }}</span>
        </b-tooltip>
        <span v-else>{{ props.row.samples }}</span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="status"
        label="Estado"
        cell-class="valign-mid"
        centered
      >
        {{ statusOptions[props.row.status] }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="datetime"
        label="Último cambio"
        cell-class="valign-mid"
        centered
      >
        {{ formatDatetime(props.row.lastUpdate) }}
      </b-table-column>
      <b-table-column v-slot="props" label="" cell-class="valign-mid" centered>
        <b-tooltip label="Detalles" type="is-light">
          <router-link
            :to="{ name: 'BatchDetails', params: { id: props.row.id } }"
          >
            <b-icon icon="information" type="is-primary" />
          </router-link>
        </b-tooltip>
      </b-table-column>
      <b-table-column
        v-if="anyBatchWithAction"
        v-slot="props"
        label="Acción"
        centered
      >
        <b-button
          v-if="props.row.action === 'prereception'"
          type="is-primary"
          @click="addBatchEvent(props.row.id, 'prereception')"
        >
          {{ actionLabels.preception }}
        </b-button>
        <b-button
          v-else-if="props.row.action === 'management'"
          type="is-primary"
          tag="router-link"
          :to="{ name: 'BatchManagement', params: { id: props.row.id } }"
        >
          {{ actionLabels.management }}
        </b-button>
      </b-table-column>
      <template slot="empty"><div class="table-empty" /></template>
    </b-table>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime } from "@/utils";

  export default {
    name: "SampleBatches",
    data() {
      return {
        sampleBatches: [],
        statusOptions: {},
        actionLabels: {
          preception: "Pre-Recepcionar",
          management: "Gestionar",
        },
        loading: false,
        batchesPerPage: 25,
        pendingOnly: true,
      };
    },
    computed: {
      batchesWithAction() {
        return this.sampleBatches.map((x) => ({
          ...x,
          action: this.getAction(x.status),
        }));
      },
      anyBatchWithAction() {
        return this.batchesWithAction.some((x) => x.action !== null);
      },
      listType() {
        return this.pendingOnly ? "pending" : "all";
      },
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.sampleBatches = [];
        this.axios
          .get(routes.listBatches.replace(":type", this.listType))
          .then((response) => {
            this.sampleBatches = response.data.results;
            this.statusOptions = response.data.statusOptions;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getAction(status) {
        const { roles } = this.$store.state.user;
        const permittedRoles = [
          process.env.VITE_ADMIN_ROLE,
          "sample-manager",
          "extr-analyst",
          "pcr-analyst",
        ];

        if (roles.some((role) => permittedRoles.includes(role))) {
          if (status === "dispatched") {
            return "prereception";
          } else if (["prereceived", "in_extraction"].includes(status)) {
            return "management";
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
      addBatchEvent(batchId, eventType) {
        this.axios
          .post(routes.addBatchEvent, { batchId, eventType })
          .then(() => {
            this.loadData();
          });
      },
      formatCounts(counts) {
        return Object.entries(counts)
          .map(([k, v]) => `${k}: ${v}`)
          .join(", ");
      },
      formatDatetime(datetime) {
        return formatDateTime(datetime);
      },
    },
  };
</script>

<style scoped></style>
