<template>
  <div class="container mt-5">
    <h1 class="title">Muestras Sin Reportar</h1>

    <p class="has-text-grey mb-4">
      Esta opción lista las muestras que ya han sido validadas pero aún no han
      sido reportadas. Reportarlas generará automáticamente un informe
      individual para cada una de ellas, así como un informe resumen para el
      conjunto informado.
    </p>

    <b-table
      v-if="samples.length > 0 || loading"
      :data="samples"
      :checked-rows.sync="selectedSamples"
      :loading="loading"
      checkable
      hoverable
      narrowed
    >
      <b-table-column
        v-slot="props"
        label="Muestra"
        width="40"
        numeric
        centered
      >
        {{ props.row.sampleId }}
      </b-table-column>
      <b-table-column v-slot="props" label="Recepción" centered>
        {{ formatDateTime(props.row.receptionDatetime) }}
      </b-table-column>
      <b-table-column v-slot="props" label="Validación" centered>
        {{ formatDateTime(props.row.validationDatetime) }}
      </b-table-column>
      <b-table-column v-slot="props" label="Resultado" centered>
        <b-tag :type="tagTypes[props.row.conclusion]">
          {{ props.row.conclusion }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" label="Observaciones">
        <span v-if="props.row.observations">
          {{ props.row.observations }}
        </span>
        <span v-else-if="props.row.annotations">
          {{ props.row.annotations }}
        </span>
      </b-table-column>

      <template slot="bottom-left">
        <b>Seleccionadas</b>: {{ selectedSamples.length }} /
        {{ samples.length }}
      </template>

      <template slot="empty"><div class="table-empty" /></template>
    </b-table>
    <div v-else-if="!loading" class="container mt-3">
      <b-message> No hay muestras sin reportar. </b-message>
    </div>

    <div v-if="samples.length > 0">
      <b-field grouped position="is-right">
        <b-button
          type="is-primary"
          :loading="submitting"
          :disabled="selectedSamples.length === 0"
          @click="submitData"
        >
          Generar Informes
        </b-button>
      </b-field>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime } from "@/utils";

  export default {
    name: "UnreportedSamples",
    data() {
      return {
        samples: [],
        selectedSamples: [],
        tagTypes: {
          POSITIVO: "is-danger",
          NEGATIVO: "is-light",
          "NO CONCLUYENTE": "is-warning",
          "REPETIR MUESTRA": "is-warning",
          RECHAZADO: "is-info",
        },
        submitting: false,
        loading: false,
      };
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.axios
          .get(routes.unreportedSamples)
          .then((response) => {
            this.samples = response.data.results;
            this.selectedSamples = [];
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      submitData() {
        this.submitting = true;
        this.axios
          .post(routes.reportSamples, {
            samples: this.selectedSamples.map((x) => Number(x["sampleId"])),
          })
          .then(() => {
            this.$buefy.toast.open({
              duration: 2000,
              message:
                "Cambios guardados correctamente. Los informes se están generando en el servidor.",
            });
            this.loadData();
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 3000,
              message: "Ha ocurrido un error",
              type: "is-danger",
            });
            this.$log.error(e);
          })
          .finally(() => {
            this.submitting = false;
          });
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime);
      },
    },
  };
</script>

<style scoped></style>
