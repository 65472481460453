<template>
  <div class="columns">
    <div class="column is-two-thirds mx-auto">
      <h1 class="title">{{ title }}</h1>

      <validation-observer v-slot="{ handleSubmit }" slim>
        <form id="userForm" @submit.prevent="handleSubmit(submit)">
          <b-input-with-validation
            v-model="formUser.username"
            rules="required|alpha_num|unique"
            name="username"
            label="Usuario"
            type="username"
            :debounce="100"
          />
          <b-input-with-validation
            v-model="formUser.displayName"
            :rules="{ required: true, regex: /^[A-Za-zÁÉÍÓÚáéíóú. ]+$/ }"
            name="displayName"
            label="Nombre"
            type="username"
            :debounce="100"
          />
          <template v-if="isNew || passwordEdit">
            <b-input-with-validation
              v-model="formUser.password"
              rules="required|min:10"
              label="Contraseña"
              name="password"
              type="password"
              password-reveal
              expanded
            >
              <template #addon-right>
                <p class="control">
                  <b-tooltip label="Generar contraseña" type="is-light">
                    <b-button
                      class="button is-primary"
                      icon-left="reload"
                      @click="regeneratePassword"
                    />
                  </b-tooltip>
                </p>
                <p v-if="passwordEdit" class="control">
                  <b-tooltip
                    label="Cancelar cambio de contraseña"
                    type="is-light"
                  >
                    <b-button
                      class="button is-danger is-light px-5"
                      icon-left="close-thick"
                      @click="disablePasswordEdit"
                    />
                  </b-tooltip>
                </p>
              </template>
            </b-input-with-validation>
          </template>
          <template v-else>
            <b-field label="Contraseña">
              <b-button type="is-light" expanded @click="enablePasswordEdit">
                Cambiar Contraseña...
              </b-button>
            </b-field>
          </template>
          <b-field label="Institución">
            <b-select v-model="formUser.institutionId" expanded>
              <option :value="null">---</option>
              <option
                v-for="institution in institutions"
                :key="institution.id"
                :value="institution.id"
              >
                {{ institution.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Roles">
            <b-taginput
              v-model="formUser.roles"
              :data="filteredRoles"
              field="name"
              open-on-focus
              attached
              autocomplete
              @typing="getFilteredRoles"
            />
          </b-field>
          <b-input-with-validation
            v-model="formUser.tokenExpiration"
            rules="required|positive"
            name="tokenExpiration"
            label="Duración de Token (horas)"
            type="number"
            min="1"
          />
          <b-field v-if="!isNew" label="Habilitado">
            <b-checkbox v-model="formUser.enabled" />
          </b-field>
          <b-field class="mt-5">
            <b-button type="is-primary" native-type="submit" :loading="saving">
              Guardar
            </b-button>
          </b-field>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
  import { generatePassword } from "@/utils";
  import { routes } from "@/api";
  import { extend, ValidationObserver } from "vee-validate";
  import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";

  export default {
    name: "UserForm",
    components: {
      BInputWithValidation,
      ValidationObserver,
    },
    props: {
      title: {
        type: String,
        required: false,
        default: "Nuevo Usuario",
      },
      user: {
        type: Object,
        required: false,
        default: null,
      },
      saving: {
        type: Boolean,
        required: false,
        default: false,
      },
      isNew: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        formUser: {
          username: "",
          displayName: "",
          password: "",
          roles: [],
          tokenExpiration: 1,
          institutionId: null,
        },
        roles: [],
        filteredRoles: [],
        institutions: [],
        usernameBlacklist: [],
        passwordEdit: false,
      };
    },
    computed: {
      availableRoles() {
        if (!this.formUser.roles) {
          return this.roles;
        }

        const userRoleNames = this.formUser.roles.map((x) => x.name);
        return this.roles.filter((x) => !userRoleNames.includes(x.name));
      },
      usernameValid() {
        if (this.isNew) {
          return !this.usernameBlacklist.includes(this.formUser.username);
        } else {
          return (
            !this.usernameBlacklist.includes(this.user.username) ||
            this.user.username === this.formUser.username
          );
        }
      },
    },
    watch: {
      user(value) {
        this.formUser = { ...value };
      },
      availableRoles() {
        this.getFilteredRoles("");
      },
    },
    created() {
      if (this.isNew) {
        this.regeneratePassword();
      } else {
        this.formUser = { ...this.user };
      }

      this.getRoles();
      this.getInstitutions();
      this.getUsernames();

      extend("unique", {
        validate: () => this.usernameValid,
        message: "Nombre de usuario no disponible",
      });
    },
    methods: {
      enablePasswordEdit() {
        this.regeneratePassword();
        this.passwordEdit = true;
      },
      disablePasswordEdit() {
        delete this.formUser.password;
        this.passwordEdit = false;
      },
      regeneratePassword() {
        this.formUser.password = generatePassword();
      },
      getFilteredRoles(text) {
        this.filteredRoles = this.availableRoles.filter((x) => {
          return (
            x.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
          );
        });
      },
      async getRoles() {
        try {
          const response = await this.axios.get(routes.getRoles);
          this.roles = response.data.results;
        } catch (e) {
          this.$log.error(e);
        }
      },
      async getInstitutions() {
        try {
          const response = await this.axios.get(routes.getInstitutions);
          this.institutions = response.data.results;
        } catch (e) {
          this.$log.error(e);
        }
      },
      async getUsernames() {
        try {
          const response = await this.axios.get(routes.getUsernames);
          this.usernameBlacklist = response.data.results;
        } catch (e) {
          this.$log.error(e);
        }
      },
      async submit() {
        this.formUser.tokenExpiration = Number(this.formUser.tokenExpiration);
        this.$emit("submit", this.formUser);
      },
    },
  };
</script>

<style scoped></style>
